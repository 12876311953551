import React from "react";

const MainFooter = () => (

    <div className="mainpageFooter">
        <img alt="coolpix" src='bottom-logo.png' />
        <br></br>
        © 2024 GenerativeNFTs.io. All Rights Reserved
    </div>

);

export default MainFooter;