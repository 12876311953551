// Services.js

import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Services = () => {
  return (
    <div>
         
         <MainHeader />

         <span id="top"></span>
        <div className="mainpage">

            <p className="menuLinks">
              <a href="/">/home</a>
              <a href="about">/about</a>
              <a href="services" className="active">/services</a>
              <a href="portfolio">/portfolio</a>
              <a href="blog">/blog</a>
              <a href="faq">/faq</a>
              <a href="contact">/contact</a>
            </p>

            
            <h1>We are a full-service NFT agency specializing in large-scale NFT drops on the Ethereum blockchain.</h1>

            <img alt="cool pic" src="servicecat.webp" className='float-end blogpics'/>
            <p>Here's how the entire process works:</p>

            <h2>🧑‍🤝‍🧑 Initial Consultation</h2>
            <div className="indents">
                <p>It all starts with emails, phone calls, and video meetings. We'd love to hear what your project is all about, so contact us anytime and let's get started. We do ask that you email us first with some basics. When you email us, please let us know:</p>
                <ul>

                    <li>Are you looking for generative art coding only, or generative art coding plus smart contract with minting page and launch services?</li>

                    <li>Please share your project road map and ideas and direction for your community. (We have learned if you don't have a strong team and solid marketing plans in place, the project typically does not do well.)</li>

                    <li>What kind of budget you are working with?</li>

                    <li>How far along are you with your project/art?</li>

                    <li>What's a timeline for when you'd like to have your project launched?</li>
                </ul>
                <p>From there, we will review your initial information and, if it seems like a good match, then a meeting is possibly scheduled for drilling down into the above and other details / goals / aspects.  After that, if everyone agrees it's going to be great working together, a service agreement is sent for you to sign, and you will make a deposit to secure our work/services on your project. </p>
            </div>

            <h2>🖼️ Perfecting the Artwork</h2>
            <div className="indents">
                <p>Typically, you will have an artist on board already for your NFTs. We will work one-on-one with that person to ensure that everything is setup properly for generation. And while simple generation is fine, we specialize in, and welcome, complex sets and ideas. When others cannot find a way to bring your art properly to life, give us a call, as we've done almost every kind of imaginable art generation out there. We go far above and beyond others in this area, doing tons of testing, generating meticulous and innovative metadata, and planning/executing plans for truly permanent storage of your tokens. (We use Arweave, NOT IPFS, as Arweave is far superior for ensuring long-term availability of your imagery!)</p>
            </div>

            <h2>🛠️ Building Out Your Smart Contract</h2>
            <div className="indents">
                <p>The smart contract is what governs all of the critical details of your drop — issuing and managing the tokens, providing all needed functionality, and distributing payments to the team per hard-coded, immutable rules. It's imperative that you work with a trusted, established company on this front!</p>
            </div>


            <h2>🖥️ Deploying Your dApp for Minting</h2>
            <img alt="cool pic" src="servicecat2.webp" className='float-end blogpics'/>
            <div className="indents">
                <p>Of course, your tokens are useless if no one can mint them. So, we build out a minting application (web site) where your community can connect and buy your NFTs safely and securely. We use state-of-the-art tech and top-tier servers to ensure optimal performance and uptime. If your set mints out in 10 seconds, our systems can handle it. (Note: It's fairly rare these days for such a fast sell out, and indeed it can be tough to sell ANY NFTs unless you have an established marketing plan.) We do not, by the way, handle marketing. But we do serve as advisors, as we've worked with dozens of teams and have witnessed what tends to wrk and what does not.</p>
            </div>

            <h2>👷 Testing Throughout the Process</h2>
            <div className="indents">
                <p>For all steps listed above, we do a ton of testing to ensure the highest quality! On the art side of things, we don't just run your art through some canned software. We have our own proprietary system, coded by Jim from the ground up, that allows for full control of every technical aspect. This allows us to do much cooler stuff than most others! We also customzrily handle difficult and/or complex NFT sets, and so we're also accustomed to doing a lot of testing. So, for the artwork, what will typicall happen is that we will supply you with various sets of 100-300 images to review, and we then make refinements to the logic as needed, until it all looks amazing! </p>
                <p>On the smart contract and dApp sides, we not only test and provide you with the opportunity to review things, but we have a formal testnet stage where we walk you through the entire process, explaining why each line of code is present and what it does. It's technical, but we've walked many, many non-tech founders through this, and they've absolutely appreciated these meetings, as they come away with a high level of assurance and confidence.</p>
            </div>

            <h2>🧑‍🤝‍🧑 Advisory Services, Project Management, and Teamwork Throughout</h2>
            <div className="indents">
                <p>Typically, once we're signed on as your devs and advisors, clients will hold regular team meetings to plan the drop. We'll be there to offer art and tech updates, and to weigh in on any and all topics that crop up.</p>
                <p>NFT drops are far more complex than simply making and selling tokens. If they were that easy, we wouldn't have had to have published hundreds of articles about generative NFT drops since 2021 when this industry really took off. <a href="blog">See our blog</a> for tons and tons of information on all aspects of generative NFTs!</p>
            </div>
            
            <h2>⚽ Ready to ge the ball rolling?</h2>
            <p>To get started working with us: ➡️➡️➡️ Drop Jim a line at <b>Jim [at] GenerativeNFTs.io</b> ⬅️⬅️⬅️</p>            

            <div className="clear"></div>
            <p className="topButton"><a href="#top">/top</a></p>

            <div className="clear"></div>
            
        </div>

        <MainFooter />

    </div>
  );
}

export default Services;
