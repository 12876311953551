// Home.js

import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Home = () => {
  return (
    <div>
         
         <MainHeader />

         <span id="top"></span>
        <div className="mainpage">

            <p className="menuLinks">
              <a href="/" className="active">/home</a>
              <a href="about">/about</a>
              <a href="services">/services</a>
              <a href="portfolio">/portfolio</a>
              <a href="blog">/blog</a>
              <a href="faq">/faq</a>
              <a href="contact">/contact</a>
            </p>
            
            <h1>We build Generative NFT sets.</h1>
            <img alt="cool pic" src="p1-2.webp" className="float-start leftpix" />
            <p><b>Welcome to the wild, wonderful world of generative NFTs, where nothing makes sense at all.</b> Abandon all logic, reason, and rational thought you may have because you're entering a (nearly) lawless world in which multi-million-dollar projects are funded in 15 minutes (hell, sometimes 15 seconds!) by selling 10,000 cat pictures to strangers.</p>

            <p>Or, you could work your tail off for months on a pioneering set, churning out the most ambitious, well-crafted work of your artistic and technical career — and then sell nothing(!) while some absolute tool rakes in 1,000 ETH for a handful of low-effort sketches with zero utility.</p>

            <p>The bottom line is that this whole "wild west" ecosystem can be frustrating and certainly defies sound explanation. It's a casino and an art gallery, a church and a brothel. It's people casually dropping millions on pixelated sketches after raking in 5000x returns on memecoins run by zero-experience GenZ "crypto bros" on the other side of the world.</p>

            <p>And yet, it's also a heck of a lot of fun!</p>

            <p>So yeah, let's talk about NFTs...</p>

            <h2>🙋Can a project really raise millions of dollars in funding via NFTs?</h2>
            <p>✅ Absolutely. Not only have thousands of NFT teams accomplished this, kickstarting successful brands, but we can personally attest to it, having been involved in numerous successful multi-million-dollar projects since 2021.</p>

            <h2>🙋Okay, so what exactly are “generative” NFTs?</h2>
            <p>✅ A normal NFT (which many people have heard of) is just a digital asset, such as a JPG, that someone owns. Could be anything — a picture of your cat, for example. Generative NFTs, though, are different. Imagine that you're drawing a cat, and that cat has a shirt, a necklace, a hat, and a background. Well, instead of an artist drawing one cat picture with these elements, she draws numerous examples of each of these parts —- e.g., 10 fur colors, 10 shirts, 10 necklaces, 10 hats, and 10 backgrounds. And then a generative NFT coder (like us) takes those parts and writes a program to create thousands of unique combinations, saving each one as an individual, unique image. In this example, with just 50 elements, there would be 100,000 possible cat pictures! (In practice, an artist will typically create closer to 200 elements, at a minimum, and we usually create sets of 10,000.) Have a look at the graphic along the right side of this page to quickly grasp the idea behind this.</p>

            <img alt="cool pic" src="tallvertical.jpg" className="float-end blogpics" />
            <h2>🙋And is it all cats?</h2>
            <p>✅ Oh, no! But, it's typically animals or characters of some sort. The most famous generative NFT set is called the Bored Ape Yacht Club. Each ape NFT is currently worth about $50k USD (though some were previously worth millions)! Have a look at the rankings on OpenSea for an indication of the kinds of animals and characters typically depicted. Most generative NFTs aren't worth $50k each, of course! But, it's fairly common for them to be many hundreds or thousands of dollars (or more, depending on the price of ETH at any given time). (Not all NFTs use ETH, but Ethereum is arguably the blockchain where the most NFTs “live.”) And of course, your art need not be animals; you could do all sorts of really amazing generative fine art as well! </p>

            <h2>🙋So you make up 10,000 character images, and then you sell all 10,000 online?</h2>
            <p>✅ Yes, that's the idea. (This is what my company helps NFT drop teams do. We program the generative art and accompanying data files, write the smart contract that facilitates all of the crypto sales and transfers, write the web application that facilitates purchasing using crypto currency, and guide teams through the tech and management aspects of the entire process.)</p>

            <h2>🙋And this raises how much capital?</h2>
            <p>✅ Well, that depends on several factors such as the mint price for each token and the price of ETH at mint time — and, of course, whether your set sells out or not. We want to be super clear here: Selling out NFT sets can be super challenging. When it comes to sales, marketing is essential. That said, it's typical for successful NFT drops to raise anywhere from $1 million on up. Much depends on the level of hype, the utility offered, and other factors.</p>

            <h2>🙋And then what? The NFT owners retire to the Bahamas?</h2>
            <p>✅ Ha, no. I mean, there are actually few rules, technically. So, if that's what you said you were going to do with the funds, and your buyers were somehow fine with that, then yes. But typically, generative NFT drops are meant as rapid launchpads for all sorts of real-life projects. And these could be anything — funding startups, game-building, funding nonprofit / charity organizations or initiatives, art projects, communities, music projects, or … the sky's the limit. As long as you (1) use the funds for what you say you're going to use the funds for, and (2) your buyers actually like what you're going to do with the funds, then that's what most buyers care most about when deciding whether to support you. Well, that and they generally like to have a nice attractive piece of unique, quality digital art to show for it as well — the nicer the better.</p>

            <h2>🙋But community is key, right?</h2>
            <p>✅ Absolutely. While generative NFTs are an exciting, viable path to fast scaling and significant funding, we've found that the best projects are focused on building active, engaged, and positive communities. Really, this is what generative NFTs are all about at their core. If you can build a great community, then you've got a significant advantage in this space, and I would encourage you to look into generative NFTs for continued growth. Keep in mind that the crypto market is volatile. ETH has seen highs above $4,700 in the past few years, and lows around $800. With that in mind, it should be clear that commuity should be the #1 concern, as you'll want holders who'll stick around and stay active if the price of ETH does something crazy (which it might!).</p>


            <h2>🙋Are generative NFT sets better than just one-off art?</h2>
            <p>✅ They're not better. They're just different, and they generally have different applications and utility. If you want to create and sell one-of-a-kind digital art, then that's amazing. A lot of NFT collectors are really into this. (They call this “1/1 digital art” — which means “one of one” or unique. <a href="https://medium.com/web-design-web-developer-magazine/terminology-of-the-nft-space-aping-mooning-rugging-paperhands-diamondhands-lfg-7524db22185c" targt="_blank" rel="noreferrer">More NFT terminology here</a>.) But if you have a vision for a new community, larger project, or initiative that requires significant funding and can provide some benefit to those who buy, then generative NFTs are an excellent option.</p>

            <h2>🙋What do you mean by “provide some benefit to those who buy”?</h2>
            <p>✅ Ahh, yes, I mean utility, which is a general term for various benefits, uses, and amenities available to NFT holders. I recommend reading <a href="https://medium.com/web-design-web-developer-magazine/people-are-really-stretching-nft-roadmap-amenities-these-days-4a95fe42e03c">our article on NFT roadmaps</a> for a more thorough treatment of this topic.</p>

            <h2>🙋So, how do I find buyers for 10,000 NFTs?</h2>
            <p>✅ Well, yeah, that's the trick, isn't it? With the financial potential offered by generative NFTs, everyone wants to hop onto the generative NFT train these days. But there are only so many people who will pony up, say, $100 - $500+ for a cat picture. As you may imagine, much of the success of a generative NFT project lies in marketing. This can happen in many ways, depending on various factors or specifics. If you've got leverage — meaning you're a known brand or celebrity or influencer with a built-in audience, then it's considerably easier than if not. But that said, anyone can enter the space and begin marketing. The better your art, the better your project, the better your growing community, the better your marketing … the better your success in getting off the ground. Marketing is a whole specialty in the NFT space. In any case, it's always a critical part of the conversation when planning a generative drop.</p>

            <h2>🙋Why do most buyers buy in?</h2>
            <p>✅ They buy in because they're excited about the project. They could be fans of the team or brand or celebrity behind the project; they could love the artwork samples they've seen; they could love the roadmap / proposed utility of the NFTs; they could love the community they've found in your project's social community; they could believe that the NFT will rise in value and thus are essentially investing; they could be “flippers” looking to quickly make some money by buying and then quickly selling for a profit; or, among many other reasons still, they could just love the whole game.</p>

            <h2>🙋What do you mean by “game”? Is there something more to all of this?</h2>
            <img alt="cool pic" src="p1-1.webp" className="float-end blogpics" />

            <p>✅ Yes, much more! You see, when we create the set of 10,000 NFT images from all of those separate parts, we don't just do so purely at random. Instead, we have the computer select the parts based on what's called a rarity table. If you're not familiar with that, recall how I said that the cat could have 10 different shirts. Well, you'd think that means that each shirt has a 10% chance of coming up each time, right? But that's no fun. So, what we do instead is assign a percentage to them. Some items might come up only 1% of the time, while others might come up, say, 12% of the time. <a href="https://medium.com/web-design-web-developer-magazine/rarity-tables-made-easy-when-planning-generative-nft-sets-e383a086e994" target="_blank" rel="noreferrer">This article</a> explains rarity tables. When we run 10,000 this way, with each item having a set rarity, it creates all sorts of interesting things, with some NFTs containing mostly common elements and other NFTs containing rare items, and sometimes multiple rare items on the same image. When it's all said and done, algorithms then rank the NFTs from 1 to 10,000 according to their rarity, with the rarest ones usually selling for much more.</p>

            <h2>🙋But, I thought they all sell for the same amount?</h2>
            <p>✅ Yes, when people first buy them, that's correct. When people mint NFTs from a generative set, they do so not knowing what NFT they will get. It's like purchasing a single collectible sports card — each with the same outer packaging (which is called the “prereveal image” by the way) and, only at some point later on, usually after the mint is complete, are all of the “wrappers” removed. This is called the “reveal” process — <a href="https://medium.com/web-design-web-developer-magazine/how-generative-nft-reveals-work-options-and-strategies-for-a-smooth-secure-and-profitable-407390e509c8" target="_blank" rel="noreferrer">here's an article about that</a> — and it allows the NFTs to be seen, and thus this is the point at which buyers can finally see what specific NFT they got. Some will be common, some will be rare, and some will be super-rare.</p>

            <h2>🙋And so all of those are able to be sold again for more?</h2>
            <p>✅ Yes, that's where “secondary marketplaces” come into play. The largest ones are <a href="https://opensea.io/" target="_blank" rel="noreferrer">OpenSea</a> and <a href="https://magiceden.io/" target="_blank" rel="noreferrer">Magic Eden</a>. On a marketplace web site, you can pull up a given collection and view the NFTs. So, all 10,000 cats (provided they all sold) would be shown there on a collection page. In the “prereveal” stage, all of the NFTs would look the same because, at that point, it's like looking at 10,000 baseball card wrappers — all of them the same on the outside. Even at this “prereveal” stage, people can and do buy and sell NFTs in a set. But afterward, once all of the rares are identified, the rarest ones will become the highest-priced. Keep in mind, by the way, that revenues for the NFT team are not solely from the primary sale. Any sales that happen on secondary markets might also receive royalties. Typically, a generative NFT set will have royalties from 5 to 7.5%, though I've see it go as low as 2.5% and as high as 10%. This means that, for each sale from one NFT holder to another, the NFT owner wallet will receive that share. This is why, if a set becomes popular and valuable, the revenues can continue over time. That all said, two important notes: (1) not all sets will see increased value after the sale; it's quite common for sets to lose value after the primary sale as well; and (2) the subject of royalties has been in a state of disarray for a few years and, as such, there are many factors that would determine whether you may or may not see any significant royalty revenue.</p>

            <h2>🙋And what are the common ones worth?</h2>
            <img alt="cool pic" src="p1-3.webp" className="float-end blogpics" />
            <p>✅ The lowest price out of the 10,000 set is what's called the floor price. This is the cheapest price at which anyone can purchase one of the NFTs in the set. Some people measure success by the floor price of a set. Certainly if the floor price is above the mint price, then every single person who minted has seen the value of their NFTs increase. Sometimes this can be dramatic. If an NFT mints for 0.08 ETH, for example, and the floor price goes to 0.24 ETH, then each person who minted has realized a 300% profit at that point (well, if they were to sell). But again, that's just the floor price. Usually, there aren't terribly many listed at the floor price. And those rares could be tens or hundreds of times more pricey than the floor ones. Not to mention the “super rare 1/1s.” And again, there is no guarantee of any level of pricing ... they could all moon and they could all "go to zero."</p>

            <h2>🙋“1/1s”? But, I thought these were 100% generated?</h2>
            <p>✅ Well yes, usually almost all of them are generated. But, almost always, the teams will sneak 1/1 artwork into the set, meant as ultimate prizes. <a href="https://medium.com/web-design-web-developer-magazine/generative-nft-sets-usually-include-super-rare-1-1s-lets-talk-about-those-particular-nfts-fa99d5f61d7b" target="_blank" rel="noreferrer">Here's an article about how those work</a>. Sometimes these are just single items, such as a shirt that the cat wears just once in 10,000. Other times, it's completely separate art by the main artist — e.g., a special cat in the artist's style that fits in with the set stylistically but is clearly not generated. These are usually considered the ultimate prizes of any collection and, if offered for sale, will fetch crazy-ridiculous prices.</p>

            <h2>🙋And where do all of these images live? Where are they stored, and how can they be permanent?</h2>
            <p>✅ There are many ways to address this. Some sets store their images on-chain, meaning they're a part of the Ethereum (or other) blockchain. Other times, perhaps more typically — especially with nicer, higher-def art as opposed to pixelated art — they're stored in systems designed for long-term and/or permanent storage. We utilize Arweave, which we believe to be the leading such storage solution. With Arweave, we pay up front for storage one time (it costs about $2 per gigabyte) and that should make the images available for "200 years" according to the Arweave whitepaper. IPFS is another popular solution, but we do not use it, as it requires ongoing "pinning" which, if forgotten or not maintained in an ongoing manner, could result in the file being deleted. So, again, always use Arweave!</p>

            <h2>🙋Cool! I’ve got an idea for a generative NFT drop. How many people does it take to do an NFT drop? How do I build a team?</h2>
            <p>✅ “It takes a village,” as they say. You need a talented artist, of course, and sometimes investors, marketers, advisors, project managers, a social media team, a tech partner, and perhaps even others. <a href="https://medium.com/web-design-web-developer-magazine/anatomy-of-a-generative-nft-drop-team-roles-and-responsibilities-for-success-on-the-ethereum-3f799c2eb2c7" target="_blank" rel="noreferrer">Here’s an article on that part</a>.</p>

            <h2>🙋What if I’m a celeb or influencer, like the idea of NFTs, but don’t yet have a team or a plan?</h2>
            <p>✅ I would advise reading the article linked to just above for starters. But (shameless self-promotion here) shepherding people through the NFT space is exactly what we at GenerativeNFTs.io do! We can help you build out a team, manage the project from start to maturity, provide guidance on all tangential issues required, tackle the complete tech end of things, and utilize our experience to share information that can help your project become much more robust and rewarding. So one  easy button here is to just email me → Jim [at] GenerativeNFTs.io 😊</p>

            <h2>🙋I'm ready to rock and roll!</h2>
            <p>✅ Amazing! All you have to do is: ➡️➡️➡️ Drop Jim a line at <b>Jim [at] GenerativeNFTs.io</b> ⬅️⬅️⬅️</p>            

            <div className="clear"></div>
            <p className="topButton"><a href="#top">/top</a></p>            
            <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Home;
