import React from "react";

const MainHeader = () => (
    <>
        <span id="top"></span>
        <div className="mainpageHeader">
            <img alt="cool pic" src='bottom-logo.png' />
        </div>
    </>
);

export default MainHeader;