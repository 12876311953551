// About.js

import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const About = () => {
  return (
    <div>
         
         <MainHeader />

        <span id="top"></span>
        <div className="mainpage">

            <p className="menuLinks">
              <a href="/">/home</a>
              <a href="about" className="active">/about</a>
              <a href="services">/services</a>
              <a href="portfolio">/portfolio</a>
              <a href="blog">/blog</a>
              <a href="faq">/faq</a>
              <a href="contact">/contact</a>
            </p>
            
            <h1>How it all started.</h1>
            
            <img src="aboutcat1.webp" alt="cool cat pic" className='float-start leftpix'/>
            <p><b>It was the weirdest thing, to be honest.</b></p>

            <p>By early 2021, COVID had pretty much killed much of my web design business. So I had more free time on my hands in those days. I spent most of it looking for new clients, and had actually just started landing new ones when the New York Times published a story "<a href="https://www.nytimes.com/2021/03/11/arts/design/nft-auction-christies-beeple.html" target="_blank" rel="noreferrer">JPG File Sells for $69 Million, as ‘NFT Mania’ Gathers Pace</a>," about the artist known as Beeple.</p>

            <p>I became absolutely obsessed with this story. I literally lost sleep over it!</p>

            <p>Over the next few months, I eagerly dove into the rabbit hole and kept on digging. And writing about it, as I always kept a blog as a web dev. Blogging has brought me dozens of clients over the years, and I always kept up a practice of writing articles anytime I had free time. even pre-COVID.</p>

            <p>Back then, most of the writing about NFTs was conceptual in nature. Even <a href="https://medium.com/web-design-web-developer-magazine/white-paper-on-nonprofit-public-sector-fundraising-via-non-fungible-tokens-nfts-a36754af3b6b" target="_blank" rel="noreferrer">my own first article on the topic</a> focused on how the tech could be used to raise money for nonprofits and other artists. But eventually, I landed in the area of <b>generative NFTs</b>, which was different from the 1/1 artwork most were talking about at the time.</p>

            <p>As a programmer, I then became obsessed with building out my own system for generating art. I hadn't even learned about rarity tables or other aspects of generative NFTs yet. I simply wanted to know how the art part worked.</p>

            <p>So I kept working on it and documenting my progress.</p>

            <p>Like I said, most other people were writing about NFT tech in general. Few were worried about HOW it worked, or about rarity tables or various logical programming problems involved. And so my articles quickly shot to the top of the SERPs, even as rudimentary as they now seem (to me).</p>

            <p>At first, I made a few bucks here and there casually helping others out. But by late May of 2021, I was getting maybe 25-50 inquiries daily. In time, that raised to maybe 100 per day. I couldn't even answer all of the emails and calls I was getting in a single day, much less attend all of the meetings people requested. So, long story short, my wife and I went all-in on GenerativeNFTs (literally 100+ hours/week at the start).</p>

            <p>It was absolutely intense and crazy in every positive and negative way.</p>

            <p>Fast-forward three years and we're still here, although the overall NFT hype has subsided considerably. I think it peaked in early 2022, and then the big bad bear market came and put a halt to most crypto projects for a long time. ETH dropped from its 2021 high of around $4,700 down to about $800 during this time. However, as of early 2024, the market seems to be warming up once again. The NFT space has expanded dramatically since 2021, now with inscription-type NFTs on Bitcoin (BTC Ordinals) and the same on Ethereum (Ethscriptions), not to mention Bitcoin getting ETF approval and subsequently hitting its all-time high, and also not to mention the popularity of memecoins (which is in ways similar to the NFT crazy of mid 2021).</p>

            <p>As for the future of ETH-based NFTs (our specialty), I think there will always be a case for them. As we've written in many articles since day one, they're perfect for funding projects. And for anyone with an established audience, they're similarly perfect for bringing together a community fo fans. I also tend to believe that the OG blue chip NFTs, which have lowered in value since 2021, will find their long-term right prices.</p>

            <p>So, yeah, this is what we do, what we have done since 2021, and what we will continue to do so long as we're asked to do it. We're NFT people!</p>

            <h2>Who you're working with:</h2>

                <img alt="cool pic" className="aboutimages" src="jpd.jpg" /><br></br>
                <b>Jim Dee</b>: Jim co-founded GenerativeNFTs.io to offer generative art programming, smart contracts, and minting dApps to NFT teams worldwide. He has coded ~300k+ NFTs with $40+ million in sales. <a href="https://medium.com/web-design-web-developer-magazine" target="_blank" rel="noreferrer">His blog</a>, read by 1+ million web3 insiders since 2021, is a definitive source of generative art coding and NFT drop team project management. <a href="https://www.linkedin.com/in/jimdee/" target="_blank" rel="noreferrer">Jim's LinkedIn profile</a> contains his entire career background and numerous references from colleagues and coworkers.
                <div className="clear"></div>

                <img alt="cool pic" className="aboutimages" src="wd.jpg" /><br></br>
                <b>Wendi Dee</b>: Wendi, an OG hacker, is skilled in business development, community building, infusing creativity into business affairs, and more. She has served on dozens of NFT drop teams, including many sell-out mints.
                <div className="clear"></div>

            <h2>But enough about us!</h2>
            <p>Let's talk about <b>your</b> project: ➡️➡️➡️ Drop Jim a line at <b>Jim [at] GenerativeNFTs.io</b> ⬅️⬅️⬅️</p>            

            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default About;
