// Blog.js

import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Blog = () => {
  return (
    <div>
         
         <MainHeader />

         <span id="top"></span>
        <div className="mainpage">

            <p className="menuLinks">
              <a href="/">/home</a>
              <a href="about">/about</a>
              <a href="services">/services</a>
              <a href="portfolio">/portfolio</a>
              <a href="blog" className="active">/blog</a>
              <a href="faq">/faq</a>
              <a href="contact">/contact</a>
            </p>
            
            <h1>We write obsessively about NFTs.</h1>

            <p>When it comes time to hire an NT agency to handle your generative NFT drop, you are best served by going with a company that can clearly demonstrate both technical expertise and subject-matter obsession. We've been active in, and actively monitoring, the NFT space since early 2021, and we've documented the journey, the strategies, the drops, the trends, tech developments, and just about every crazy happenning the space has seen ever since it all went parabolic in 2021. Whether you hire us or not, the information below is sure to provide immense value to your team. This is a page to bookmark for sure!</p>

            <p className="text-center">Jump down to topic:&nbsp;&nbsp;&nbsp;🎨<a href="#genArt">Generative Art Info</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            💻<a href="#AI">AI Tutorials</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            🧑‍🤝‍🧑<a href="#nftTeamMgmt"> NFT Team Management</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            🏭<a href="#NFTindustry"> NFT Industry Info</a></p>
            <p className="text-center">(Or, go to ✏️<a href="https://medium.com/web-design-web-developer-magazine" target="_blank" rel="noreferrer">Jim's Blog</a> on Medium.com)</p>


            <div className="articleContainer">

                <span id="genArt"></span>
                <h2>Articles by Jim Dee about Generative Art Preparation, Rarity Tables, and the Like</h2>
                <img alt="cool pic" src="blogcats1.webp" className='float-end blogpics'/>
                <p>The articles below discuss numerous issues and considerations involved in creating generative NFT sets—especially focused on the artwork preparation end of things. If you need assistance with generating your artwork, please contact Jim — Jim [at] GenerativeNFTs.io — who can custom-code a solution for any type of generative NFT project, from simple trait-shuffling to truly complex custom generative systems. All of these articles below are from <a href="https://medium.com/web-design-web-developer-magazine">Jim's NFT blog, here</a>.</p>
                <ul>

                <li><a href="https://medium.com/web-design-web-developer-magazine/whats-going-on-in-the-generative-nft-world-these-days-an-update-as-of-fall-2023-efddcd488183" target="_blank" rel="noreferrer">What’s Going On in the Generative NFT World These Days? An Update as of Fall 2023</a> (pub. 09-01-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/what-dimensions-should-pixelated-nfts-be-7b3dce5ac10f" target="_blank" rel="noreferrer">What Dimensions Should Pixelated NFTs Be? Looking at Punks and Other On-Chain-Type NFT / PFP Designs</a> (pub. 08-24-2023) </li>

                <li><a href="https://medium.com/web-design-web-developer-magazine/generative-nft-supply-consideration-for-future-drops-a7a96592e2e4" target="_blank" rel="noreferrer">Generative NFT Supply Consideration for Future Drops. You Sold 10k, So How Many Should Be in Your Gen2?</a> (pub. 06-19-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-strategy-should-you-show-the-current-mint-count-on-your-nft-minting-page-c5961adfec7b" target="_blank" rel="noreferrer">NFT Strategy: Should You Show the Current Mint Count on Your NFT Minting Page?</a> (pub. 06-15-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/animated-nfts-fascinate-me-so-ive-been-building-a-1-1-collection-using-ai-photoshop-code-84c453ec115c" target="_blank" rel="noreferrer">Animated NFTs Fascinate Me, So I’ve Been Building a 1/1 Collection Using AI, PhotoShop, &amp; Code</a> (pub. 03-23-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/a-few-valuable-lessons-about-animated-nfts-just-in-case-youre-thinking-about-doing-them-37284ee97d8f" target="_blank" rel="noreferrer">A Few Valuable Lessons About *Animated* NFTs, Just in Case You’re Thinking About Doing Them</a> (pub. 02-22-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/glitch-deck-nfts-quietly-launch-90gigs-of-glitch-art-insanity-on-the-way-to-the-eth-blockchain-2dcc285299c5" target="_blank" rel="noreferrer">Glitch Deck NFTs Quietly Launch — 90Gigs of Glitch Art Insanity on the Way to the ETH Blockchain</a> (pub. 02-15-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-to-calculate-the-number-of-possible-unique-nfts-in-a-generative-nft-set-d3eb574f508" target="_blank" rel="noreferrer">How to Calculate the Number of Possible Unique NFTs in a Generative NFT Set</a> (pub. 01-04-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/quick-easy-idea-for-ensuring-that-lucky-1-1-super-rare-minters-in-generative-nft-sets-get-pfps-da8edd53a016" target="_blank" rel="noreferrer">Quick &amp; Easy Idea for Ensuring that Lucky 1/1 Super Rare Minters in Generative NFT Sets Get PFPs</a> (pub. 12-15-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/generative-coders-take-politics-aside-the-donald-trump-nft-drop-was-remarkably-poorly-designed-4dd6e626f81e" target="_blank" rel="noreferrer">Generative Coder’s Take: Politics Aside, the Donald Trump NFT Drop Was Remarkably Poorly Designed</a> (pub. 12-15-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/a-practical-reason-why-generative-nft-hierarchy-rarity-really-comes-in-handy-320285d44808" target="_blank" rel="noreferrer">A Practical Reason Why Generative NFT Hierarchy / Rarity Really Comes in Handy</a> (pub. 12-01-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-generative-nft-sets-can-implement-degrees-of-separation-for-maximum-uniqueness-and-variety-5c536019d5ff" target="_blank" rel="noreferrer">How Generative NFT Sets Can Implement “Degrees of Separation” for Maximum Uniqueness and Variety</a> (pub. 08-24-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/what-went-wrong-with-the-isekai-meta-nft-drop-and-other-generative-nft-drops-in-the-past-f2a9e9bd98d" target="_blank" rel="noreferrer">What Went Wrong with the “Isekai Meta” NFT Drop (and Other Generative NFT Drops in the Past)?</a> (pub. 08-22-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/examining-an-ape-centric-generative-nft-project-artwork-trait-prep-lets-look-at-the-cheeky-7333cd187c78" target="_blank" rel="noreferrer">Examining an Ape-Centric Generative NFT Project Artwork &amp; Trait Prep: Let’s Look at the Cheeky Rangas</a> (pub. 07-27-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/a-visual-guide-to-generative-nft-artwork-setup-photoshop-and-procreate-friendly-how-tos-399358d99f3a" target="_blank" rel="noreferrer">A Visual Guide to Generative NFT Artwork Setup — Photoshop and Procreate-Friendly How-Tos</a> (pub. 05-31-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/artwork-and-nfts-how-many-traits-are-needed-for-a-10k-generative-nft-pfp-set-5f80a2d7fdf4" target="_blank" rel="noreferrer">Artwork and NFTs: How Many Traits Are Needed for a 10k Generative NFT PFP Set?</a> (pub. 05-17-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/tips-for-generative-nft-artists-preparing-for-worldwide-attention-d7e489b305f1" target="_blank" rel="noreferrer">Tips for Generative NFT Artists — Preparing for Worldwide Attention</a> (pub. 03-18-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/the-trouble-with-overlapping-traits-in-generative-nft-programming-71fcbe01c040" target="_blank" rel="noreferrer">The Trouble with Overlapping Traits in Generative NFT Programming</a> (pub. 03-09-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-generative-nft-reveals-work-options-and-strategies-for-a-smooth-secure-and-profitable-407390e509c8" target="_blank" rel="noreferrer">How Generative NFT Reveals Work — Options and Strategies for a Smooth, Secure, and Profitable Reveal Process</a> (pub. 02-05-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/generative-nft-sets-usually-include-super-rare-1-1s-lets-talk-about-those-particular-nfts-fa99d5f61d7b" target="_blank" rel="noreferrer">Generative NFT Sets Usually Include *Super-Rare* 1/1s. Let’s Talk About Those Particular NFTs</a> (pub. 01-06-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/rarity-tables-made-easy-when-planning-generative-nft-sets-e383a086e994" target="_blank" rel="noreferrer">Rarity Tables Made Easy When Planning Generative NFT Sets</a> (pub. 12-21-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/logic-rules-in-generative-nft-sets-help-curate-the-art-but-need-to-be-understood-as-well-9dba066c0ed7" target="_blank" rel="noreferrer">Logic Rules in Generative NFT Sets Help Curate the Art, but Need to Be Understood, As Well</a> (pub. 09-05-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-to-prepare-a-rarity-table-for-a-generative-nft-art-programmer-1c081db52f29" target="_blank" rel="noreferrer">How to Prepare a Rarity Table for a Generative NFT Art Programmer</a> (pub. 08-18-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/at-what-pixel-dimensions-are-most-generative-nft-art-projects-built-9344d3998ac0" target="_blank" rel="noreferrer">At What Pixel Dimensions Are Most Generative NFT Art Projects Built?</a> (pub. 08-10-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-to-prepare-artwork-for-a-generative-nft-programmer-970adf59c042" target="_blank" rel="noreferrer">How to Prepare Artwork for a Generative NFT Programmer</a> (pub. 08-04-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/why-yes-generative-art-is-actually-art-please-stop-criticizing-it-3bb3bdd9c754" target="_blank" rel="noreferrer">Why Yes, Generative Art IS Actually Art! Please Stop Criticizing It!</a> (pub. 07-29-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-to-post-giant-10-000-sets-of-nfts-on-opensea-2a8efd4c836e" target="_blank" rel="noreferrer">How to Post Giant 10,000+ Sets of NFTs on OpenSea</a> (pub. 07-16-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/problems-in-generative-nft-coding-technical-uniqueness-versus-aesthetic-uniqueness-34b83638cbd9" target="_blank" rel="noreferrer">Problems in Generative NFT Coding: Technical Uniqueness Versus Aesthetic Uniqueness</a> (pub. 07-12-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/information-regarding-generative-nft-coding-services-2d492aa18d4e" target="_blank" rel="noreferrer">Information Regarding Generative NFT Coding Services</a> (pub. 07-08-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/on-programmatically-generating-10-000-tuxedo-cat-portrait-nfts-e82cf6b60a9d" target="_blank" rel="noreferrer">On Programmatically Generating 10,000 Tuxedo Cat Portrait NFTs</a> (pub. 06-30-2021) </li>
                </ul>

                <p className="topButton"><a href="#top">/top</a></p>
                <div className="clear"></div>
                <span id="AI"></span>
                <h2>Articles About AI — Using Midjourney, etc.</h2>
                <img alt="cool pic" src="blogcats2.webp" className='float-end blogpics'/>
                <p>The articles below introduce people to AI through guides and tutorials. As of March 2023, this is a new area for Jim, who has released a generative NFT set leveraging AI imagery plus code, and also a 1/1 art set that marries AI, Photoshop, hand-drawn, and coded elements. These articles will help newcomers and experienced people get up to speed on using AI in art and NFT projects.</p>
                <ul>
                <li><a href="https://medium.com/web-design-web-developer-magazine/looking-at-openais-sora-amazing-videos-made-from-simple-text-prompts-62878b2f0d78" target="_blank" rel="noreferrer">Looking at OpenAI’s “Sora” — Amazing Videos Made from Simple Text Prompts</a> (pub. 02-15-2024) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/wild-ways-to-get-midjourney-ai-to-imitate-something-making-a-bored-ape-derivative-from-a-real-f901aababe9" target="_blank" rel="noreferrer">Wild Ways to Get Midjourney AI to Imitate Something: Making a Bored Ape Derivative from a Real Bored Ape NFT</a> (pub. 04-04-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/the-creative-process-a-peek-into-my-midjourney-ai-prompting-while-creating-an-original-animated-7fe19475c5af" target="_blank" rel="noreferrer">The Creative Process: A Peek into My Midjourney AI Prompting While Creating an Original Animated NFT</a> (pub. 03-31-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/configuring-midjourney-settings-a-look-at-the-remix-mode-for-refining-your-ai-prompting-cd83118ac261" target="_blank" rel="noreferrer">Configuring Midjourney Settings: A Look at the Remix Mode for Refining your AI Prompting</a> (pub. 03-30-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/turn-your-thoughts-into-art-how-to-get-started-with-midjourney-for-ai-image-creation-d67b7211291a" target="_blank" rel="noreferrer">Turn Your Thoughts Into Art: How to Get Started with Midjourney for AI Image Creation</a> (pub. 03-27-2023) </li>
                </ul>

                <p className="topButton"><a href="#top">/top</a></p>
                <div className="clear"></div>

                <span id="nftTeamMgmt"></span>

                <h2>Articles on NFT Team Management — Building Teams, Running Projects, Strategies Along the Way, etc. </h2>
                <img alt="cool pic" src="blogcats3.webp" className='float-end blogpics'/>
                <p>The articles here discuss NFT team building and management topics. Unless you're quite famous, doing an NFT drop will usually require an entire team of people — founders, devs, advisors, marketers, social media experts, ambassadors, and all sorts of support. These articles discuss many of the most important topics that require planning and forethought if you're to be successful. If you're interested in having GenerativeNFTs be on your advisory team, drop us a line. Reach out to our CEO, Wendi, via our website at <a href="https://GenerativeNFTs.io">GenerativeNFTs.io</a>. (Pop a note into our contact form that you're looking for assistance with advisory services.)</p>
                <ul>
                <li><a href="https://medium.com/web-design-web-developer-magazine/marketing-tweets-using-reach-experimenting-with-a-platform-that-helps-creators-reach-audiences-5ef2d76825a3" target="_blank" rel="noreferrer">Marketing Tweets using /REACH — Experimenting With a Platform That Helps Creators “Reach” Audiences</a> (pub. 02-28-2024) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/whats-if-you-want-need-a-new-smart-contract-in-the-middle-of-an-nft-sale-099b4e049d1c" target="_blank" rel="noreferrer">What If You Want / Need a New Smart Contract in the Middle of an NFT Sale?</a> (pub. 12-18-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/with-nft-royalties-as-they-are-now-basically-100-voluntary-how-can-nft-teams-budget-for-989a21f9bb8d" target="_blank" rel="noreferrer">With NFT Royalties as They Are Now (Basically 100% Voluntary), How Can NFT Teams Budget for Royalties?</a> (pub. 09-19-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/what-dimensions-should-pixelated-nfts-be-7b3dce5ac10f" target="_blank" rel="noreferrer">What Dimensions Should Pixelated NFTs Be? Looking at Punks and Other On-Chain-Type NFT / PFP Designs</a> (pub. 08-24-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/opensea-announces-another-big-change-for-nft-royalties-heres-the-low-down-on-what-to-expect-91e195cc2def" target="_blank" rel="noreferrer">OpenSea Announces Another Big Change for NFT Royalties. Here’s the Low-Down on What to Expect</a> (pub. 08-17-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/is-on-chain-a-really-big-selling-point-for-nfts-im-gonna-say-no-absolutely-not-here-s-why-22256d551d2" target="_blank" rel="noreferrer">Is “On Chain” a Really Big Selling Point for NFTs? I’m Gonna Say “No, Absolutely Not.” Here’s Why.</a> (pub. 07-15-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/dont-give-up-there-are-options-available-when-generative-nft-sets-don-t-sell-out-e562b2088a9d" target="_blank" rel="noreferrer">Don’t Give Up! There are Options Available When Generative NFT Sets Don’t Sell Out</a> (pub. 07-08-2023) </li>
                <li><a href="hhttps://medium.com/web-design-web-developer-magazine/are-dupe-bored-apes-a-problem-thoughts-about-dupes-in-generative-nft-art-coding-dfe5e7580d2e" target="_blank" rel="noreferrer">Are “Dupe” Bored Apes a Problem? Thoughts about Dupes in Generative NFT Art Coding</a> (pub. 06-23-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/a-closer-look-at-winter-for-credit-card-processing-for-nft-minting-applications-cdb9a18b1146" target="_blank" rel="noreferrer">A Closer Look at Winter for Credit Card Processing for NFT Minting Applications</a> (pub. 03-22-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/credit-card-processors-for-generative-nft-drops-comparing-some-of-the-cc-solution-providers-d8052f71d477" target="_blank" rel="noreferrer">Credit Card Processors for Generative NFT Drops: Comparing Some of the CC Solution Providers</a> (pub. 03-15-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/show-me-you-are-serious-and-not-some-bogus-project-actual-quote-from-an-nft-influencer-e72d1413a849" target="_blank" rel="noreferrer">“Show Me You Are Serious and Not Some Bogus Project” (Actual Quote from an NFT “Influencer”)</a> (pub. 03-07-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-space-influencers-my-definition-of-what-an-ideal-nft-influencers-qualifications-look-like-704f48525bf0" target="_blank" rel="noreferrer">NFT-Space Influencers: My Definition of What an Ideal NFT Influencer’s Qualifications Look Like. Part 1 of 2!</a> (pub. 03-03-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/a-few-valuable-lessons-about-animated-nfts-just-in-case-youre-thinking-about-doing-them-37284ee97d8f" target="_blank" rel="noreferrer">A Few Valuable Lessons About *Animated* NFTs, Just in Case You’re Thinking About Doing Them</a> (pub. 02-22-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/continuing-nft-royalty-battle-of-blur-vs-17bd28157ddf" target="_blank" rel="noreferrer">Continuing NFT Royalty Battle of Blur vs. OpenSea Is a Nightmare for NFT Teams, Artists, and Creators</a> (pub. 02-20-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-teams-hate-articles-like-this-but-sales-tax-on-nfts-is-yet-another-issue-to-consider-while-823ad0011038" target="_blank" rel="noreferrer">NFT Teams Hate Articles Like This, But Sales Tax on NFTs Is Yet Another Issue to Consider While Strategizing</a> (pub. 02-16-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/budgeting-for-nft-projects-with-influencer-marketing-assistance-via-on-chain-affiliate-marketing-29f9988c388e" target="_blank" rel="noreferrer">Budgeting for NFT Projects With Influencer Marketing Assistance Via On-Chain Affiliate Marketing</a> (pub. 01-26-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/affiliate-marketing-for-nft-drops-and-nft-greeting-cards-a-condensed-recap-of-my-longer-previous-fafe246d5001" target="_blank" rel="noreferrer">Affiliate Marketing for NFT Drops and NFT Greeting Cards: A Condensed Recap of My Longer Previous Article</a> (pub. 01-18-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/announcing-an-affiliate-program-for-generative-nft-drops-web2-marketing-ported-to-web3-finally-7b532c356d0f" target="_blank" rel="noreferrer">Announcing an Affiliate Program for Generative NFT Drops — Web2 Marketing Ported to Web3, Finally!</a> (pub. 01-18-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-teams-free-yourself-from-never-ending-ipfs-pinning-fees-uploading-images-to-arweave-via-the-a94ca51e99f4" target="_blank" rel="noreferrer">NFT Teams: Free Yourself from Never-Ending IPFS Pinning Fees: Uploading Images to Arweave via the CLI Method</a> (pub. 01-17-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/but-why-ethereum-for-generative-nfts-what-about-all-of-the-other-chains-89f4c4cb1198" target="_blank" rel="noreferrer">But Why *Ethereum* for Generative NFTs? What About All of the Other Chains?</a> (pub. 01-10-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/opensea-addresses-scammers-with-new-collection-settings-but-they-create-new-issues-for-nft-teams-42c091521e63" target="_blank" rel="noreferrer">OpenSea Addresses Scammers with New Collection Settings, but They Create New Issues for NFT Teams</a> (pub. 12-24-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/quick-easy-idea-for-ensuring-that-lucky-1-1-super-rare-minters-in-generative-nft-sets-get-pfps-da8edd53a016" target="_blank" rel="noreferrer">Quick &amp; Easy Idea for Ensuring that Lucky 1/1 Super Rare Minters in Generative NFT Sets Get PFPs</a> (pub. 12-15-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/looking-at-the-holding-back-inventory-strategy-for-nft-drops-45d9282e2216" target="_blank" rel="noreferrer">Looking at the “Holding Back” Inventory Strategy for NFT Drops</a> (pub. 11-29-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/do-you-gotta-have-a-gimmick-in-the-nft-space-lets-look-at-a-current-example-of-a-great-gimmick-10d7620130e0" target="_blank" rel="noreferrer">Do You “Gotta Have a Gimmick” in the NFT Space? Let’s Look at a Current Example of a Great Gimmick</a> (pub. 11-23-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/looking-at-openseas-openrarity-settings-in-your-nft-collection-1a3479e12837" target="_blank" rel="noreferrer">Looking at OpenSea’s OpenRarity Settings in Your NFT Collection</a> (pub. 11-17-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/artist-pay-in-generative-nft-sets-how-much-should-they-get-42e8c84e1326" target="_blank" rel="noreferrer">Artist Pay in Generative NFT Sets: How Much Should They Get?</a> (pub. 09-16-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/strategies-for-reserved-nfts-in-generative-nft-sets-23213db68552" target="_blank" rel="noreferrer">Strategies for Reserved NFTs in Generative NFT Sets</a> (pub. 08-31-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/bringing-new-users-into-the-web3-nft-space-is-clearly-essential-but-how-8beee8c45594" target="_blank" rel="noreferrer">Bringing New Users into the Web3 / NFT Space Is Clearly Essential. But How?</a> (pub. 08-29-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-generative-nft-sets-can-implement-degrees-of-separation-for-maximum-uniqueness-and-variety-5c536019d5ff" target="_blank" rel="noreferrer">How Generative NFT Sets Can Implement “Degrees of Separation” for Maximum Uniqueness and Variety</a> (pub. 08-24-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/what-went-wrong-with-the-isekai-meta-nft-drop-and-other-generative-nft-drops-in-the-past-f2a9e9bd98d" target="_blank" rel="noreferrer">What Went Wrong with the “Isekai Meta” NFT Drop (and Other Generative NFT Drops in the Past)?</a> (pub. 08-22-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-nft-teams-can-address-the-problem-of-timing-and-momentum-in-generative-nft-marketing-6c3ee52c1c75" target="_blank" rel="noreferrer">How NFT Teams Can Address the Problem of Timing and Momentum in Generative NFT Marketing</a> (pub. 08-12-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/whitelist-count-doesnt-predict-how-many-nft-mints-will-happen-2c04e7ead963" target="_blank" rel="noreferrer">Whitelist 👏 Count 👏Doesn’t 👏Predict 👏How 👏Many 👏NFT 👏 Mints 👏Will 👏Happen.</a> (pub. 08-04-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/the-smart-contract-setmaxsupply-is-a-handy-thing-so-long-as-you-implement-a-proper-control-9be86c10476f" target="_blank" rel="noreferrer">The Smart Contract setMaxSupply() Is a Handy Thing — So Long as You Implement a Proper Control</a> (pub. 07-25-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/hard-dollar-real-value-utility-in-the-nft-space-a-look-at-the-deck-of-degeneracy-and-what-makes-80d8a2d86c50" target="_blank" rel="noreferrer">Hard-Dollar, Real Value Utility in the NFT Space: A Look at the Deck of Degeneracy and What Makes It Special</a> (pub. 07-21-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/literary-lessons-in-the-nft-space-originality-is-much-more-than-just-thinking-of-a-new-pfp-animal-2bc6e9a1bcc4" target="_blank" rel="noreferrer">Literary Lessons in the NFT Space: Originality is Much More than Just Thinking of a New PFP Animal</a> (pub. 07-17-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/my-nft-free-mint-sold-out-heres-what-i-learned-and-what-i-d-change-if-i-were-to-do-another-827b30dd3e96" target="_blank" rel="noreferrer">My NFT Free Mint “Sold Out” — Here’s What I Learned and What I’d Change If I Were to Do Another</a> (pub. 07-15-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-much-of-your-generative-nft-pfp-drop-should-you-show-prior-to-the-drop-4558f38e2a0a" target="_blank" rel="noreferrer">How Much of Your Generative NFT PFP Drop Should You Show Prior to the Drop?</a> (pub. 07-12-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/ethereum-nft-smart-contract-ascii-art-various-examples-and-how-tos-6388a716869e" target="_blank" rel="noreferrer">Ethereum NFT Smart Contract ASCII Art: Various Examples and How-Tos</a> (pub. 06-14-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-freemint-lessons-learned-so-far-turns-out-it-aint-easy-to-be-goblintown-frens-be551b3f2f2b" target="_blank" rel="noreferrer">NFT #Freemint Lessons Learned So Far — Turns Out It Ain’t Easy to Be Goblintown, Frens</a> (pub. 06-08-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/professional-liability-and-web3-development-an-issue-with-no-clear-solution-currently-7c295c540875" target="_blank" rel="noreferrer">Professional Liability and Web3 Development: An Issue with No Clear Solution Currently</a> (pub. 05-31-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/thinking-long-term-when-it-comes-to-nft-project-airdrops-for-holders-building-sustainable-nft-7c84a5d8c3e2" target="_blank" rel="noreferrer">Thinking Long-Term When It Comes to NFT Project Airdrops for Holders; Building Sustainable NFT Projects</a> (pub. 05-16-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/what-should-the-mint-price-be-when-launching-a-set-of-10-000-generative-nfts-on-the-eth-blockchain-4744f9fc4ef" target="_blank" rel="noreferrer">What Should the Mint Price Be When Launching a Set of 10,000 Generative NFTs on the ETH Blockchain?</a> (pub. 05-12-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/the-offset-approach-to-fair-nft-reveals-and-other-metadata-reveal-strategies-considerations-2e2c69e5c274" target="_blank" rel="noreferrer">The Offset Approach to Fair NFT Reveals, and Other Metadata Reveal Strategies / Considerations</a> (pub. 05-09-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/the-whole-nft-ecosystem-is-way-more-precarious-than-anyone-ever-talks-about-bdf2b90e21c1" target="_blank" rel="noreferrer">The Whole NFT Ecosystem is WAY More Precarious Than Anyone Ever Talks About</a> (pub. 05-06-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/doxing-is-not-a-privilege-vet-the-living-f-out-of-your-web3-dev-s-90dc6f4b205d" target="_blank" rel="noreferrer">Doxing is Not a “Privilege”; Vet the Living F*** Out of Your Web3 Dev(s)</a> (pub. 05-06-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/people-are-really-stretching-nft-roadmap-amenities-these-days-4a95fe42e03c" target="_blank" rel="noreferrer">People are *Really* Stretching NFT Roadmap Amenities These Days - A Look at NFT&nbsp;Utility</a> (pub. 04-22-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-to-ensure-that-you-receive-nft-sale-royalties-on-the-new-coinbase-nft-marketplace-f484b6eadeb3" target="_blank" rel="noreferrer">How to Ensure that You Receive NFT Sale Royalties on the New Coinbase NFT Marketplace</a> (pub. 04-21-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/strategies-for-nft-teams-regarding-whitelists-allow-lists-presale-lists-how-many-wallets-to-23bb30ac8037" target="_blank" rel="noreferrer">Strategies for NFT Teams Regarding Whitelists, Allow Lists, Presale Lists— How Many Wallets to Collect</a> (pub. 03-28-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/tips-for-generative-nft-artists-preparing-for-worldwide-attention-d7e489b305f1" target="_blank" rel="noreferrer">Tips for Generative NFT Artists — Preparing for Worldwide Attention</a> (pub. 03-18-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/what-goes-on-behind-the-scenes-when-a-10k-generative-nft-set-reduces-its-supply-4d6fe70f10f2" target="_blank" rel="noreferrer">What Goes on Behind the Scenes When a 10k Generative NFT Set Reduces Its Supply?</a> (pub. 03-14-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/accounting-and-tax-planning-for-generative-nft-drop-teams-ce7e0e1e55cd" target="_blank" rel="noreferrer">Accounting and Tax Planning for Generative NFT Drop Teams</a> (pub. 03-03-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/anatomy-of-a-generative-nft-drop-team-roles-and-responsibilities-for-success-on-the-ethereum-3f799c2eb2c7" target="_blank" rel="noreferrer">Anatomy of a Generative NFT Drop Team: Roles and Responsibilities for Success on the Ethereum Blockchain</a> (pub. 02-11-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-generative-nft-reveals-work-options-and-strategies-for-a-smooth-secure-and-profitable-407390e509c8" target="_blank" rel="noreferrer">How Generative NFT Reveals Work — Options and Strategies for a Smooth, Secure, and Profitable Reveal Process</a> (pub. 02-05-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/ready-to-buy-into-the-bored-ape-franchise-2fed4f1f478d" target="_blank" rel="noreferrer">Ready to Buy Into the Bored Ape Franchise? Look Beyond Trait Rarity to Aesthetics and Branding for Value</a> (pub. 01-30-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/can-nfts-show-established-brand-logos-in-generative-artwork-9e412b98bf56" target="_blank" rel="noreferrer">Can NFTs Show Established Brand Logos in Generative Artwork?</a> (pub. 01-23-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/generative-nft-sets-usually-include-super-rare-1-1s-lets-talk-about-those-particular-nfts-fa99d5f61d7b" target="_blank" rel="noreferrer">Generative NFT Sets Usually Include *Super-Rare* 1/1s. Let’s Talk About Those Particular NFTs</a> (pub. 01-06-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/generative-nft-programming-articles-how-to-prep-for-doing-your-own-nft-drops-383e87525c59" target="_blank" rel="noreferrer">Generative NFT Programming Articles — How to Prep for Doing Your Own NFT Drops</a> (pub. 12-02-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/terminology-of-the-nft-space-aping-mooning-rugging-paperhands-diamondhands-lfg-7524db22185c" target="_blank" rel="noreferrer">Terminology of the NFT Space — Aping, Mooning, Rugging, Paperhands, Diamondhands, LFG</a> (pub. 11-02-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/lessons-learned-doing-generative-nft-art-drops-997f6d38b2f9" target="_blank" rel="noreferrer">Lessons Learned Doing Generative NFT Art Drops</a> (pub. 08-17-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/thoughts-on-nft-marketing-and-why-its-easier-for-generative-and-or-larger-sets-collections-73e4f80f903b" target="_blank" rel="noreferrer">Thoughts on NFT Marketing (and Why It’s Easier for Generative and/or Larger Sets / Collections)</a> (pub. 07-27-2021) </li>

                </ul>

                <p className="topButton"><a href="#top">/top</a></p>
                <div className="clear"></div>

                <span id="NFTindustry"></span>
                <img alt="cool pic" src="blogcats4.webp" className='float-end blogpics'/>
                <h2>NFT Industry Pieces</h2>
                <p>The articles linked to below are general NFT industry writings by Jim. Like the pieces above, they are linked to in order from most recent to the older pieces. That said, many of the older pieces are still quite relevant in terms of various observations and trends in the generative NFT market. So, if you've got time, this section is highly worth reading.</p>
                <ul>

                <h3>NFT Basics</h3>
                
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-security-basics-part-1-of-2-general-cyber-security-278e9c612313" target="_blank" rel="noreferrer">NFT Security Basics, Part 1 of 2: General Cyber Security</a> (pub. 11-08-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-basics-the-top-800-things-anyone-can-easily-do-to-stay-safe-in-the-crypto-nft-space-717d743ed1a8" target="_blank" rel="noreferrer">NFT Security Basics, Part 2 of 2: Crypto Wallet Safety</a> (pub. 11-07-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-basics-how-to-mint-your-first-nft-from-an-nft-drops-mint-page-42b085053ff8" target="_blank" rel="noreferrer">NFT Basics: How to Mint Your First NFT from an NFT Drop’s Mint Page</a> (pub. 11-05-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-basics-how-to-setup-a-metamask-wallet-for-buying-ethereum-nfts-e85d4ba29523" target="_blank" rel="noreferrer">NFT Basics: How to Setup a MetaMask Wallet for Buying Ethereum NFTs</a> (pub. 11-01-2022) </li>

                <h3>Misc Commentary &amp; Ideas</h3>
                <li><a href="https://medium.com/web-design-web-developer-magazine/are-bored-apes-mutants-and-other-yuga-assets-going-to-zero-81c3ef836601" target="_blank" rel="noreferrer">Are Bored Apes, Mutants, and other Yuga Assets Going to Zero?</a> (pub. 03-06-2024) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/with-bitcoin-hovering-near-its-all-time-high-and-ordinals-absolutely-on-fire-are-eth-nfts-dead-0c0e5f137e9c" target="_blank" rel="noreferrer">With Bitcoin Hovering Near Its All-Time High and Ordinals Absolutely on Fire, Are ETH NFTs Dead?</a> (pub. 03-05-2024) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/with-eth-gas-at-36-i-deployed-configd-a-10k-nft-set-for-about-10-using-facetnft-here-s-how-1c9a4d8ef237" target="_blank" rel="noreferrer">With ETH Gas at 36, I Deployed & Config’d a 10k NFT Set for About $10 Using FacetNFT — Here’s How!</a> (pub. 02-20-2024) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/what-the-hell-are-zitty-brits-looking-at-a-practically-free-mint-a0b6d6175ac6" target="_blank" rel="noreferrer">What the Hell Are “Zitty Brits”? Looking at a (Practically Free) Mint</a> (pub. 01-26-2024) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/thoughts-on-inscribing-html-for-high-res-ethscriptions-or-ordinals-with-ultra-low-gas-9dbec07adc4a" target="_blank" rel="noreferrer">Thoughts on Inscribing HTML for High-Res Ethscriptions (or Ordinals) with Ultra-Low Gas</a> (pub. 01-22-2024) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/can-you-switch-an-erc721-nft-drop-to-an-ethscriptions-drop-e-g-if-your-erc721-did-not-sell-out-269f92fa4b5d" target="_blank" rel="noreferrer">Can You Switch an ERC721 NFT Drop to an Ethscriptions Drop — e.g., If Your ERC721 Did Not Sell Out?</a> (pub. 01-19-2024) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/ethscriptions-now-surging-in-popularity-handle-on-chain-and-also-ipfs-arweave-type-nfts-d82533b94152" target="_blank" rel="noreferrer">Ethscriptions, Now Surging in Popularity, Handle On-Chain AND Also IPFS/Arweave Type NFTs</a> (pub. 01-15-2024) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/next-up-nakamingos-a-flamingo-centric-ethscriptions-derivative-of-the-popular-nakamigo-nft-set-d1186d14e744" target="_blank" rel="noreferrer">Next Up: Nakamingos🦩 — A Flamingo-Centric Ethscriptions Derivative of the Popular Nakamigo NFT Set</a> (pub. 01-10-2024) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/glitchdeck-nft-project-update-lowering-the-mint-price-by-10x-to-0-004-eth-10-usd-33693b92fdba" target="_blank" rel="noreferrer">GlitchDeck NFT Project Update — Lowering the Mint Price By 10x to 0.004 ETH (~$10 USD)</a> (pub. 01-10-2024) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/the-ittybits-nft-drop-a-clever-and-ambitious-project-packed-into-just-12x12-pixels-5a4278d36606" target="_blank" rel="noreferrer">The IttyBits NFT Drop — A Clever and Ambitious Project Packed Into Just 12x12 Pixels</a> (pub. 01-02-2024) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/ethscriptions-bookmarks-a-handy-reference-34f715d59843" target="_blank" rel="noreferrer">Ethscriptions Bookmarks — A Handy Reference</a> (pub. 12-28-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/are-you-2-000-richer-anon-a-follow-up-on-my-ethscriptions-article-of-dec-1-lets-talk-punks-42e7c0efa8b2" target="_blank" rel="noreferrer">Are You $2,000 Richer, Anon? A Follow-Up on My Ethscriptions Article of Dec 1. Let’s Talk Punks!</a> (pub. 12-27-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/lets-talk-about-ethscriptions-nfts-again-as-significant-opportunities-abound-there-potentially-416075cbbb7e" target="_blank" rel="noreferrer">Let’s Talk About Ethscriptions NFTs Again, As Significant Opportunities Abound There (Potentially, IMHO)</a> (pub. 12-01-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/what-are-rare-pepe-nfts-98f6b2102022" target="_blank" rel="noreferrer">What Are Rare Pepe NFTs?</a> (pub. 11-30-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/thoughts-on-hype-rollingstones-your-nfts-are-actually-finally-totally-worthless-article-23f9cd2e9b48" target="_blank" rel="noreferrer">Thoughts on Hype: RollingStone’s “Your NFTs Are Actually — Finally — Totally Worthless” Article</a> (pub. 09-26-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/ens-apathy-im-simply-done-with-ens-aside-from-owning-my-own-name-anyway-b392cc9d5095" target="_blank" rel="noreferrer">ENS Apathy… I’m Simply Done with ENS (aside from owning my own name, anyway).</a> (pub. 09-18-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/crystal-hefner-talks-nfts-web3-travel-music-and-more-acf6976b36bf" target="_blank" rel="noreferrer">Crystal Hefner Talks NFTs, Web3, Travel, Music, and More</a> (pub. 08-03-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/writers-leverage-blockchain-tech-for-securing-copyrights-an-alt-use-case-for-arweave-or-any-2c8c814c7f4d" target="_blank" rel="noreferrer">Writers: Leverage Blockchain Tech for Securing Copyrights — An Alt Use Case for Arweave or Any Blockchain</a> (pub. 08-01-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/figuring-out-where-the-eth-goes-for-nft-sales-is-easy-with-a-little-smart-contract-sleuthing-ecf20da485b9" target="_blank" rel="noreferrer">Figuring Out Where the ETH Goes For NFT Sales Is Easy With a Little Smart Contract Sleuthing</a> (pub. 07-28-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/yep-devs-get-scammed-too-sometimes-heres-how-i-lost-12-ethscription-nfts-in-minutes-7e23ecacf8ea" target="_blank" rel="noreferrer">Yep, Devs Get Scammed, Too (Sometimes). Here’s How I Lost 12 Ethscription NFTs in Minutes</a> (pub. 07-20-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/stories-about-justin-biebers-bored-ape-nft-are-usually-misleading-19fd57ffb9c0" target="_blank" rel="noreferrer">Stories About Justin Bieber’s Bored Ape NFT Are Usually Misleading</a> (pub. 07-06-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/will-nft-pay-be-the-one-fingers-crossed-on-my-latest-cc-payment-processor-integration-f087ce323b36" target="_blank" rel="noreferrer">Will NFT Pay Be “The One”? Fingers Crossed on My Latest CC Payment Processor Integration</a> (pub. 06-30-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-to-wreck-a-web3-brand-in-three-easy-steps-looking-at-the-40-million-azuki-nft-fiasco-1ef20fccef80" target="_blank" rel="noreferrer">How to Wreck a Web3 Brand in Three Easy Steps: Looking at the $40 Million Azuki NFT Fiasco</a> (pub. 06-28-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/did-you-miss-bitcoin-ordinals-check-out-ethscriptions-new-nft-like-assets-on-the-eth-chain-2450ec535ef9" target="_blank" rel="noreferrer">Did You Miss Bitcoin Ordinals? Check Out Ethscriptions! New NFT-like Assets on the ETH Chain</a> (pub. 06-16-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-to-easily-buy-pepe-and-other-erc-20-meme-coins-f6c803f10895" target="_blank" rel="noreferrer">How to Easily Buy $PEPE and other ERC-20 Meme Coins</a> (pub. 05-05-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nfts-vs-meme-coins-nfts-probably-need-to-wait-this-out-but-itll-be-worth-it-in-the-end-580a68ec5fb2" target="_blank" rel="noreferrer">NFTs vs. Meme Coins: NFTs Probably Need to Wait this Out, But It’ll be Worth It in the End</a> (pub. 05-05-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nfts-are-the-future-of-nonprofit-fundraising-its-time-to-embrace-the-tech-and-reach-new-donors-37fb765afea3" target="_blank" rel="noreferrer">NFTs Are the Future of Nonprofit Fundraising. It’s Time to Embrace the Tech and Reach New Donors</a> (pub. 05-04-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/make-unique-tokens-great-again-once-again-trump-nfts-fail-to-provide-unique-tokens-d30fffa09ac4" target="_blank" rel="noreferrer">Make Unique Tokens Great Again: Once Again, Trump NFTs Fail to Provide Unique Tokens</a> (pub. 04-19-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/doge-stamp-functionality-continues-to-evolve-there-are-still-10k-doge-stamps-too-e3134e2ce110" target="_blank" rel="noreferrer">Doge Stamp Functionality Continues to Evolve! There Are Still Under 10k Doge Stamps, Too!</a> (pub. 04-12-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/doge-nft-stamps-how-to-do-them-a-chance-to-get-in-early-6c358573b1d4" target="_blank" rel="noreferrer">Doge NFT “Stamps”: How to Do Them: A Chance to Get in Early</a> (pub. 04-04-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/the-creative-process-a-peek-into-my-midjourney-ai-prompting-while-creating-an-original-animated-7fe19475c5af" target="_blank" rel="noreferrer">The Creative Process: A Peek into My Midjourney AI Prompting While Creating an Original Animated NFT</a> (pub. 03-31-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/turn-your-thoughts-into-art-how-to-get-started-with-midjourney-for-ai-image-creation-d67b7211291a" target="_blank" rel="noreferrer">Turn Your Thoughts Into Art: How to Get Started with Midjourney for AI Image Creation</a> (pub. 03-27-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/show-me-you-are-serious-and-not-some-bogus-project-actual-quote-from-an-nft-influencer-e72d1413a849" target="_blank" rel="noreferrer">“Show Me You Are Serious and Not Some Bogus Project” (Actual Quote from an NFT “Influencer”)</a> (pub. 03-07-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-space-influencers-my-definition-of-what-an-ideal-nft-influencers-qualifications-look-like-704f48525bf0" target="_blank" rel="noreferrer">NFT-Space Influencers: My Definition of What an Ideal NFT Influencer’s Qualifications Look Like. Part 1 of 2!</a> (pub. 03-03-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-teams-hate-articles-like-this-but-sales-tax-on-nfts-is-yet-another-issue-to-consider-while-823ad0011038" target="_blank" rel="noreferrer">NFT Teams Hate Articles Like This, But Sales Tax on NFTs Is Yet Another Issue to Consider While Strategizing</a> (pub. 02-16-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/generative-nft-marketing-how-the-what-the-fluff-drop-did-it-628934abfb3e" target="_blank" rel="noreferrer">Chronicling the “What the Fluff?” Drop — Wild Marketing Including a Rug Scare Stunt for Good Measure</a> (pub. 01-31-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/imagine-sending-0-129-eth-200-usd-directly-to-500-holders-of-an-nft-community-details-here-2ccdb6c4a2a" target="_blank" rel="noreferrer">Imagine Sending 0.129 ETH (~$200 USD) Directly to 500+ Holders of an NFT Community (Details Here!)</a> (pub. 01-30-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/budgeting-for-nft-projects-with-influencer-marketing-assistance-via-on-chain-affiliate-marketing-29f9988c388e" target="_blank" rel="noreferrer">Budgeting for NFT Projects With Influencer Marketing Assistance Via On-Chain Affiliate Marketing</a> (pub. 01-26-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/free-nfts-for-the-lunar-new-year-from-nftgreetings-xyz-a-new-web3-greeting-card-company-80915c8d2d9b" target="_blank" rel="noreferrer">Free NFTs for the Lunar New Year from NFTGreetings.xyz, a New Web3 Greeting Card Company</a> (pub. 01-23-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/affiliate-marketing-for-nft-drops-and-nft-greeting-cards-a-condensed-recap-of-my-longer-previous-fafe246d5001" target="_blank" rel="noreferrer">Affiliate Marketing for NFT Drops and NFT Greeting Cards: A Condensed Recap of My Longer Previous Article</a> (pub. 01-18-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/announcing-an-affiliate-program-for-generative-nft-drops-web2-marketing-ported-to-web3-finally-7b532c356d0f" target="_blank" rel="noreferrer">Announcing an Affiliate Program for Generative NFT Drops — Web2 Marketing Ported to Web3, Finally!</a> (pub. 01-18-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/but-why-ethereum-for-generative-nfts-what-about-all-of-the-other-chains-89f4c4cb1198" target="_blank" rel="noreferrer">But Why *Ethereum* for Generative NFTs? What About All of the Other Chains?</a> (pub. 01-10-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/looking-at-the-feetpix-nft-collection-a-most-unusual-drop-at-what-may-be-the-beginning-of-an-nft-8dbe14cc985d" target="_blank" rel="noreferrer">Looking at the Feetpix NFT Collection — A Most Unusual Drop at What May Be the Beginning of an NFT Bull Run</a> (pub. 01-10-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/designing-a-generative-nft-drop-featuring-watches-a-marketing-idea-for-watch-manufacturers-4fed662c2bff" target="_blank" rel="noreferrer">Designing a Generative NFT Drop Featuring Watches: A Marketing Idea for Watch Manufacturers</a> (pub. 01-07-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/i-asked-an-ai-to-write-an-essay-on-building-a-rarity-table-for-a-generative-nft-drop-fe8888a23f1f" target="_blank" rel="noreferrer">I Asked an AI to Write an Essay on Building a Rarity Table for a Generative NFT Drop</a> (pub. 01-06-2023) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/more-arweave-permanent-storage-chat-storing-images-for-slow-mints-nft-projects-that-dont-sell-acbf8a7b1044" target="_blank" rel="noreferrer">More Arweave Permanent Storage Chat: Storing Images for Slow Mints (NFT Projects That Don’t Sell Out)</a> (pub. 12-21-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/generative-coders-take-politics-aside-the-donald-trump-nft-drop-was-remarkably-poorly-designed-4dd6e626f81e" target="_blank" rel="noreferrer">Generative Coder’s Take: Politics Aside, the Donald Trump NFT Drop Was Remarkably Poorly Designed</a> (pub. 12-15-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/using-arweave-through-ardrive-for-storing-10-000-generative-nfts-permanently-how-to-get-it-done-a80ac8e36f30" target="_blank" rel="noreferrer">Using Arweave Through ArDrive for Storing 10,000 Generative NFTs Permanently: How to Get It Done</a> (pub. 12-15-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/getting-ar-tokens-as-a-u-s-ea89885dfc7a" target="_blank" rel="noreferrer">Getting AR Tokens as a U.S. Citizen: Preparation for a Look at Arweave for NFT Image / Metadata Storage</a> (pub. 12-15-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/chefboirdoge-launches-a-second-generative-nft-set-to-follow-mutts-meet-the-mixed-breeds-428132367916" target="_blank" rel="noreferrer">ChefBoiRDoge Launches a Second Generative NFT Set to Follow “Mutts.” Meet the “Mixed Breeds”!</a> (pub. 12-12-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/hollywood-costume-designer-world-renowned-fashion-illustrator-an-amazing-art-fashion-nft-ad267ffc1178" target="_blank" rel="noreferrer">Hollywood Costume Designer + World Renowned Fashion Illustrator = An Amazing Art/Fashion NFT Project</a> (pub. 12-05-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-security-basics-part-1-of-2-general-cyber-security-278e9c612313" target="_blank" rel="noreferrer">NFT Security Basics, Part 1 of 2: General Cyber Security</a> (pub. 11-08-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/the-whole-nft-royalty-ecosystem-is-a-giant-disaster-but-it-was-also-inevitable-given-the-market-df1bfda7f6e8" target="_blank" rel="noreferrer">The Whole NFT Royalty Ecosystem Is a Giant Disaster, But It Was Also Inevitable Given the Market Size</a> (pub. 11-08-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-basics-the-top-800-things-anyone-can-easily-do-to-stay-safe-in-the-crypto-nft-space-717d743ed1a8" target="_blank" rel="noreferrer">NFT Security Basics, Part 2 of 2: Crypto Wallet Safety</a> (pub. 11-07-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-basics-how-to-mint-your-first-nft-from-an-nft-drops-mint-page-42b085053ff8" target="_blank" rel="noreferrer">NFT Basics: How to Mint Your First NFT from an NFT Drop’s Mint Page</a> (pub. 11-05-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-basics-how-to-setup-a-metamask-wallet-for-buying-ethereum-nfts-e85d4ba29523" target="_blank" rel="noreferrer">NFT Basics: How to Setup a MetaMask Wallet for Buying Ethereum NFTs</a> (pub. 11-01-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/generativenfts-changes-slogan-to-web3-tech-and-strategy-experts-f3a2a77514c2" target="_blank" rel="noreferrer">GenerativeNFTs Changes Slogan to “Web3 Tech and Strategy Experts”</a> (pub. 10-05-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-to-wrap-an-og-linagee-name-registrar-nft-for-viewing-and-or-selling-on-opensea-f5fe48715830" target="_blank" rel="noreferrer">How to Wrap an OG Linagee Name Registrar NFT for Viewing and/or Selling on OpenSea</a> (pub. 10-04-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/historical-nfts-a-look-at-the-linagee-name-registrar-contract-discovery-and-gold-rush-3f604fe9f7cc" target="_blank" rel="noreferrer">Historical NFTs: A Look at the Linagee Name Registrar Contract Discovery and Gold Rush</a> (pub. 09-30-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/ive-got-a-great-idea-for-an-nft-marketing-initiative-for-a-vegas-casino-anyone-want-to-chat-666d11ba0948" target="_blank" rel="noreferrer">I’ve Got a Great Idea for an NFT Marketing Initiative for a Vegas Casino. Anyone Want to Chat?</a> (pub. 09-23-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/any-digital-illustrators-want-to-collab-on-a-free-mint-nft-project-d2c21068958f" target="_blank" rel="noreferrer">Any Digital Illustrators Want to Collab on a Free Mint NFT Project? And Yes, It’ll Be Kinda-Sorta an Ape Derivative</a> (pub. 09-15-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/ens-domains-as-nft-sets-looking-at-some-niche-investing-opportunities-accadbbdfe24" target="_blank" rel="noreferrer">ENS Domains as NFT Sets: Looking at Some Niche Investing Opportunities</a> (pub. 09-13-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/even-as-an-eth-maxi-i-have-to-mention-the-y00ts-nft-phenomenon-7cfaa5d9a22" target="_blank" rel="noreferrer">Even As an ETH Maxi, I Have to Mention the y00ts NFT Phenomenon</a> (pub. 09-07-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-to-discover-interesting-ens-domain-emoji-words-that-currently-work-on-windows-machines-only-64fc7e787a6a" target="_blank" rel="noreferrer">How to Discover Interesting ENS Domain Emoji Words That Currently Work on Windows Machines Only</a> (pub. 08-26-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/looking-into-the-ens-craze-within-the-nft-space-are-eth-domains-nfts-92faa040feb5" target="_blank" rel="noreferrer">Looking into the ENS Craze within the NFT Space: Are .eth Domains NFTs?</a> (pub. 08-19-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/a-funny-experience-i-had-with-an-nft-minting-dapp-4d189f2c863c" target="_blank" rel="noreferrer">A Funny Experience I Had with an NFT Minting dApp</a> (pub. 08-16-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nfts-for-boomers-genxers-millennials-and-genz-3bc4aa539ba9" target="_blank" rel="noreferrer">NFTs for Boomers, GenXers, Millennials, and GenZ</a> (pub. 08-02-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-and-why-i-built-a-name-generator-for-my-loopieloo-generative-nft-set-f2b570f04658" target="_blank" rel="noreferrer">How (and Why) I Built a Name Generator for My LoopieLoo Generative NFT Set</a> (pub. 07-29-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/ranking-the-many-nationality-nft-free-mints-of-late-will-any-stand-the-test-of-time-78886140683" target="_blank" rel="noreferrer">Ranking the Many “Nationality NFT” Free Mints of Late — Will Any Stand the Test of Time?</a> (pub. 07-20-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/careful-buying-nfts-via-collection-offers-on-looksrare-and-probably-other-marketplaces-157c3180973c" target="_blank" rel="noreferrer">Careful Buying NFTs Via Collection Offers on LooksRare and Probably Other Marketplaces</a> (pub. 07-06-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/pinata-is-not-a-viable-long-term-solution-for-facilitating-decentralized-nft-file-storage-on-ipfs-f27896da2ea5" target="_blank" rel="noreferrer">Pinata is NOT a Viable Long-Term Solution for Facilitating Decentralized NFT File Storage on IPFS</a> (pub. 06-30-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/quick-hack-to-refresh-opensea-metadata-on-many-nfts-quickly-6b8a2a52b080" target="_blank" rel="noreferrer">Quick Hack to Refresh OpenSea Metadata on Many NFTs Quickly</a> (pub. 06-30-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/i-just-upped-my-free-mint-wallet-limit-to-25-dogs-666fc7f67a2" target="_blank" rel="noreferrer">I Just Upped My Free-Mint Wallet Limit to 25 Dogs! How Does 15 Cents (or less!) Per ETH Mint Strike You?!</a> (pub. 06-23-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/nft-freemint-lessons-learned-so-far-turns-out-it-aint-easy-to-be-goblintown-frens-be551b3f2f2b" target="_blank" rel="noreferrer">NFT #Freemint Lessons Learned So Far — Turns Out It Ain’t Easy to Be Goblintown, Frens</a> (pub. 06-08-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/free-eth-nft-mint-going-on-right-now-9-663-doggos-get-yourself-some-loopieloonfts-for-just-gas-3ccc8124852c" target="_blank" rel="noreferrer">Free ETH NFT Mint Going on Right Now! (9,663 doggos) Get Yourself Some LoopieLooNFTs for Just Gas</a> (pub. 06-07-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/looking-to-do-a-free-mint-generative-nft-set-like-goblintown-wtf-and-or-a-flash-drop-1763e46180ae" target="_blank" rel="noreferrer">Looking to Do a Free Mint Generative NFT Set Like GoblinTown.wtf and/or a Flash Drop?</a> (pub. 06-03-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/professional-liability-and-web3-development-an-issue-with-no-clear-solution-currently-7c295c540875" target="_blank" rel="noreferrer">Professional Liability and Web3 Development: An Issue with No Clear Solution Currently</a> (pub. 05-31-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/does-lowballing-nfts-actually-work-an-experiment-on-opensea-part-1-b74f69d0d153" target="_blank" rel="noreferrer">Does Lowballing NFTs Actually Work? An Experiment on OpenSea (Part 1)</a> (pub. 05-13-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/doctor-sniffy-nfts-help-a-hong-kong-dog-rescue-insider-pics-and-an-awesome-generative-art-preview-d89fa1e8d6a1" target="_blank" rel="noreferrer">Doctor Sniffy NFTs Help a Hong Kong Dog Rescue — Insider Pics and an Awesome Generative Art Preview</a> (pub. 05-13-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/funding-2-million-projects-via-generative-nfts-launching-amazing-communities-at-scale-937c48f0f74a" target="_blank" rel="noreferrer">Funding $2+ Million Projects via Generative NFTs — Launching Amazing Communities at Scale</a> (pub. 05-11-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/misc-irl-nft-utility-challenges-event-nfts-and-access-pass-nfts-d653bbc59cab" target="_blank" rel="noreferrer">Misc. IRL NFT Utility Challenges — Event NFTs and Access Pass NFTs</a> (pub. 05-09-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/the-whole-nft-ecosystem-is-way-more-precarious-than-anyone-ever-talks-about-bdf2b90e21c1" target="_blank" rel="noreferrer">The Whole NFT Ecosystem is WAY More Precarious Than Anyone Ever Talks About</a> (pub. 05-06-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/doxing-is-not-a-privilege-vet-the-living-f-out-of-your-web3-dev-s-90dc6f4b205d" target="_blank" rel="noreferrer">Doxing is Not a “Privilege”; Vet the Living F*** Out of Your Web3 Dev(s)</a> (pub. 05-06-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/two-valuable-nft-investing-reminders-from-the-world-of-women-galaxy-drop-f7fbbe21af41" target="_blank" rel="noreferrer">Two Valuable NFT Investing Reminders from the World of Women Galaxy Drop</a> (pub. 03-29-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/essential-nft-space-bookmarks-for-discovering-drops-marketing-nfts-trading-purchasing-and-a31f599bacfd" target="_blank" rel="noreferrer">Essential NFT Space Bookmarks for Discovering Drops, Marketing NFTs, Trading / Purchasing, and Research</a> (pub. 03-24-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/an-open-letter-to-the-bad-vegan-sarma-melngailis-heres-a-legit-way-to-get-your-restaurant-back-b338cbe27f68" target="_blank" rel="noreferrer">An Open Letter to the “Bad Vegan,” Sarma Melngailis: Here’s a Legit Way to Get Your Restaurant Back</a> (pub. 03-22-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/tips-for-generative-nft-artists-preparing-for-worldwide-attention-d7e489b305f1" target="_blank" rel="noreferrer">Tips for Generative NFT Artists — Preparing for Worldwide Attention</a> (pub. 03-18-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/bayc-uncaringly-leaves-its-bakc-doggos-out-in-the-bitter-cold-which-totally-sucks-for-investors-e5ea92565744" target="_blank" rel="noreferrer">BAYC Uncaringly Leaves Its BAKC Doggos Out in the Bitter Cold, Which Totally Sucks for Investors</a> (pub. 03-18-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/year-of-the-woman-nfts-now-minting-want-to-see-some-art-that-people-are-really-raving-about-ea1d5e9259af" target="_blank" rel="noreferrer">‘Year of the Woman’ NFTs Now Minting. Want to See Some Art that People Are Really Raving About?</a> (pub. 03-10-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/year-of-the-woman-nft-drop-perfectly-captures-the-zeitgeist-of-the-rising-crypto-art-scene-24c6ca50f05d" target="_blank" rel="noreferrer">“Year of the Woman” NFT Drop Perfectly Captures the Zeitgeist of the Rising Crypto-Art Scene</a> (pub. 02-25-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/the-mind-blowing-math-of-the-cool-cats-cool-pets-nft-drop-59cdcd840dc7" target="_blank" rel="noreferrer">The Mind-Blowing Math of the Cool Cats / Cool Pets NFT Drop</a> (pub. 02-04-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/a-survey-of-generative-nft-drops-run-by-women-and-or-featuring-female-artists-80be504d3276" target="_blank" rel="noreferrer">A Survey of Generative NFT Drops Run by Women (and/or Featuring Female Artists)</a> (pub. 02-02-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/ready-to-buy-into-the-bored-ape-franchise-2fed4f1f478d" target="_blank" rel="noreferrer">Ready to Buy Into the Bored Ape Franchise? Look Beyond Trait Rarity to Aesthetics and Branding for Value</a> (pub. 01-30-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/cool-nft-projects-now-minting-82f922a76702" target="_blank" rel="noreferrer">Cool NFT Projects Now Minting!</a> (pub. 01-25-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/napoleon-dynamite-actor-jon-heder-drops-generative-nft-set-order-of-the-tigons-on-theta-drop-449d151e0c06" target="_blank" rel="noreferrer">“Napoleon Dynamite” Actor Jon Heder Drops Generative NFT Set — Order of the Tigons — on Theta Drop</a> (pub. 01-24-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/2022-year-of-the-high-nft-mints-looking-at-the-math-on-a-few-high-profile-upcoming-drops-66bcd671fb14" target="_blank" rel="noreferrer">2022: Year of the High-$$$ NFT Mints— Looking at the Math on a Few High-Profile Upcoming Drops</a> (pub. 01-17-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/a-quick-look-at-the-fascinating-c-01-nft-drop-coming-up-on-jan-14-36a2a69a8f41" target="_blank" rel="noreferrer">A Quick Look at the Fascinating C-01 NFT Drop Coming Up on Jan 14</a> (pub. 01-04-2022) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/a-tale-of-two-smart-contracts-budverse-nfts-vs-pepsi-mic-drop-e8b730019cbd" target="_blank" rel="noreferrer">A Tale of Two Smart Contracts: Budverse NFTs vs. Pepsi Mic Drop</a> (pub. 12-27-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/dyor-example-for-nft-investors-looking-at-a-potentially-awful-nft-drop-in-the-ape-space-5dca55edd2d8" target="_blank" rel="noreferrer">DYOR Example for NFT Investors: Looking at a Potentially Awful NFT Drop in the Ape Space</a> (pub. 12-13-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/big-brand-corporate-nfts-and-pr-why-huge-nft-drops-are-still-problematic-for-the-giants-7e662ec2b2bd" target="_blank" rel="noreferrer">Big-Brand Corporate NFTs and PR: Why Huge NFT Drops Are Still Problematic for the Giants</a> (pub. 12-10-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-pepsi-can-make-you-at-least-a-couple-grand-in-a-few-weeks-with-their-mic-drop-nft-706cea7920cd" target="_blank" rel="noreferrer">How Pepsi Can Make You (At Least) a Couple Grand in a Few Weeks — With Their “Mic Drop” NFT</a> (pub. 12-09-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/budweiser-quietly-drops-1-936-nfts-and-barely-anyone-knows-about-it-5a585658fc34" target="_blank" rel="noreferrer">Budweiser Quietly Drops 1,936 NFTs And Barely Anyone Knows About It</a> (pub. 11-30-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/praying-hands-club-taps-into-something-much-deeper-than-any-previous-nft-drop-to-date-509c3cbcc439" target="_blank" rel="noreferrer">Praying Hands Club Taps Into Something Much Deeper than Any Previous NFT Drop to Date 🙏</a> (pub. 11-21-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/the-queen-of-the-sandbox-preps-to-drop-9-999-generative-nft-keys-to-new-online-realm-sandhelm-cd6faf9f78d1" target="_blank" rel="noreferrer">The “Queen of Sandhelm” Preps to Drop 9,999 Generative NFT Keys to Her New Online Gaming Realm</a> (pub. 11-13-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/the-imakittycat-nft-project-will-challenge-your-ability-to-distinguish-art-from-reality-4f721df67bf1" target="_blank" rel="noreferrer">The “ImAKittyCat” NFT Project Will Challenge Your Ability to Distinguish Art from Reality</a> (pub. 11-09-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/terminology-of-the-nft-space-aping-mooning-rugging-paperhands-diamondhands-lfg-7524db22185c" target="_blank" rel="noreferrer">Terminology of the NFT Space — Aping, Mooning, Rugging, Paperhands, Diamondhands, LFG</a> (pub. 11-02-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/desperate-apewives-sell-out-obsessively-following-a-successful-16-hour-nft-drop-f00cc0f02183" target="_blank" rel="noreferrer">Desperate ApeWives Sell-Out: Obsessively Following a Successful 16-Hour NFT Drop</a> (pub. 10-28-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/can-the-boonji-project-bump-the-mekas-off-the-top-spot-for-nft-investors-9e08880e179a" target="_blank" rel="noreferrer">Can the Boonji Project Bump the Mekas Off the Top Spot for NFT Investors?</a> (pub. 10-13-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/boonji-to-the-moonji-the-dramatic-dutch-auction-nft-drop-that-didnt-quite-make-it-yet-ee5ef61bc5e3" target="_blank" rel="noreferrer">Boonji To The Moonji? The Dramatic Dutch Auction NFT Drop that Didn’t Quite Make It (Yet).</a> (pub. 10-13-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/so-these-guys-in-the-nft-space-plan-to-literally-set-1-million-on-fire-c2784b73b86d" target="_blank" rel="noreferrer">So, These People in the NFT Space Plan to Literally Set $1 Million on FIRE!</a> (pub. 10-13-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/are-nft-investment-opportunities-lurking-in-the-opensea-stats-rankings-page-607859eabfa6" target="_blank" rel="noreferrer">Are NFT Investment Opportunities Lurking in the OpenSea Stats / Rankings Page?</a> (pub. 10-12-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/crypto-mos-the-mustachioed-art-patron-s-generative-nft-set-e67acf70545c" target="_blank" rel="noreferrer">Crypto Mo’s — The Mustachioed Art Patron’s Generative NFT Set</a> (pub. 10-09-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/the-feeding-frenzy-of-the-mekaverse-3bfc42dc9b66" target="_blank" rel="noreferrer">The Feeding Frenzy of the MekaVerse</a> (pub. 10-07-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/upcoming-nft-drops-that-look-promising-for-investors-a2b891072004" target="_blank" rel="noreferrer">Upcoming NFT Drops that Look Promising for Investors</a> (pub. 10-04-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/the-dark-side-of-the-generative-nft-space-scamers-and-hackers-and-fud-oh-my-d275bf37a3f0" target="_blank" rel="noreferrer">The Dark Side of the Generative NFT Space: Scammers and Hackers and FUD, Oh My!</a> (pub. 09-11-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/riding-along-with-the-superfuzz-bad-batch-sell-out-flash-drop-nft-sale-da01b73c42c9" target="_blank" rel="noreferrer">Riding Along with the Superfuzz “Bad Batch” Sell Out Flash Drop NFT Sale</a> (pub. 09-06-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/riding-along-for-an-nft-drop-with-the-canine-cartel-which-sold-out-and-moved-500-eth-in-an-hour-a1629ce03b5a" target="_blank" rel="noreferrer">Riding Along for an NFT Drop with the Canine Cartel, which SOLD OUT and Moved 500 ETH in an Hour</a> (pub. 08-24-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/riding-along-for-an-nft-drop-with-the-rebel-kids-familiars-which-sold-out-584ebadce7eb" target="_blank" rel="noreferrer">Riding Along for an NFT Drop with the Rebel Kids Familiars (Which Sold Out!)</a> (pub. 08-11-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/why-yes-generative-art-is-actually-art-please-stop-criticizing-it-3bb3bdd9c754" target="_blank" rel="noreferrer">Why Yes, Generative Art IS Actually Art! Please Stop Criticizing It!</a> (pub. 07-29-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/thoughts-on-nft-marketing-and-why-its-easier-for-generative-and-or-larger-sets-collections-73e4f80f903b" target="_blank" rel="noreferrer">Thoughts on NFT Marketing (and Why It’s Easier for Generative and/or Larger Sets / Collections)</a> (pub. 07-27-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/diving-into-the-world-of-nfts-both-via-theoretical-discussion-and-actually-minting-selling-nfts-75a57c66ffb7" target="_blank" rel="noreferrer">Diving into the World of NFTs — Both via Theoretical Discussion and Actually Minting / Selling NFTs</a> (pub. 06-07-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/how-musicians-with-hit-tunes-from-any-era-can-cash-in-on-nfts-d5223b4e5b15" target="_blank" rel="noreferrer">How Musicians with Hit Tunes from Any Era Can Cash In on NFTs</a> (pub. 05-24-2021) </li>
                <li><a href="https://medium.com/web-design-web-developer-magazine/white-paper-on-nonprofit-public-sector-fundraising-via-non-fungible-tokens-nfts-a36754af3b6b" target="_blank" rel="noreferrer">White Paper on Nonprofit / Public Sector Fundraising via Non-Fungible Tokens (NFTs)</a> (pub. 05-11-2021) </li>

                </ul>

            </div>            
                    
            <div className="clear"></div>

            <h2>🤩 Wow, that's a lot of info!</h2>
            <p>Yep, there's a lot to NFTs! So let's chat more about them: ➡️➡️➡️ Drop Jim a line at <b>Jim [at] GenerativeNFTs.io</b> ⬅️⬅️⬅️</p>            

            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Blog;
