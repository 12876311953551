import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './css/generative.css';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Blog from './components/Blog';
import Faq from './components/Faq';
import Contact from './components/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';


// NOTE: IF ESLINT ERRORS, TRY THIS: Change build command to:  
// CI= npm run build
// This allows Netlify to NOT treat ESlint warnings as errors

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
