// Faq.js

import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

import Accordion from 'react-bootstrap/Accordion';

const Faq = () => {
  return (
    <div>
         
         <MainHeader />

         <span id="top"></span>
        <div className="mainpage">

            <p className="menuLinks">
              <a href="/">/home</a>
              <a href="about">/about</a>
              <a href="services">/services</a>
              <a href="portfolio">/portfolio</a>
              <a href="blog">/blog</a>
              <a href="faq" className="active">/faq</a>
              <a href="contact">/contact</a>
            </p>
            
            <h1>Got NFT questions?</h1>
            <p>We've got answers!</p>

            <Accordion>

                <Accordion.Item eventKey="0">
                    <Accordion.Header>How do I prepare my artwork for generating a set of NFTs?</Accordion.Header>
                    <Accordion.Body>We're glad you asked! Jim Dee is a leading voice in the generative NFT world who has guidance for you in <a href="https://medium.com/web-design-web-developer-magazine/how-to-prepare-artwork-for-a-generative-nft-programmer-970adf59c042" target="_blank" rel="noreferrer">this article</a>. If you want to dig into more details, check out <a href="https://medium.com/web-design-web-developer-magazine/how-to-prepare-artwork-for-a-generative-nft-programmer-970adf59c042" target="_blank" rel="noreferrer">Jim's post on NFT dimensions</a> as well.
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>What is generative art and generative art coding?</Accordion.Header>
                    <Accordion.Body>Also known as “generative NFT art” or “generative art code/coding,” these terms point to artwork that's created through the power of programming. Imagine a drawing of a cat. For each aspect of the drawing (e.g., the background, the fur color, the shirt, the hat, the sunglasses, etc.), an artist would draw several possibilities. These individual layers are exported individually, and then our code programmatically creates different combinations, all based on a rarity table that governs how rare or common each item is. This creates 10,000 (or some other number) of programmatically unique images, each with its own metadata file that describe what's there. When they are all together in a collection, this also creates an interesting heirarchy of rarity from the most common combinations to the most rare combos. There's a ton more to it, but that's the gist.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>What's a smart contract, and why do I need one?</Accordion.Header>
                    <Accordion.Body>A smart contract is the code that lives on the blockchain (typically Ethereum at our agency), which governs all aspects of the NFTs (supply number, pricing, and other important details). The contract collects and distributes the proceeds from the sale, allows marketplaces to facilitate buying and selling of the tokens, and tells marketplaces where to look for the token images and metadata.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header>What's a minting page?</Accordion.Header>
                    <Accordion.Body>Your minting page is an exciting step in the birth of each iteration of your generative art! This webpage is where your customers purchase your one-of-a-kind NFT artwork. Our expert NFT developers code a custom page that looks fantastic and works smoothly.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                    <Accordion.Header>Could you provide some must-have info about NFTs, rolled into one article?</Accordion.Header>
                    <Accordion.Body>Absolutely! Jim Dee explains the ins and outs of generative NFT projects in <a href="https://medium.com/web-design-web-developer-magazine/information-regarding-generative-nft-coding-services-2d492aa18d4e" target="_blank" rel="noreferrer">this article</a>. You'll learn about our agency's approach to client projects, a run-down of generative coding for NFTs, and more.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                    <Accordion.Header>If I'm not an artist (or not a digital artist), can you help?</Accordion.Header>
                    <Accordion.Body>Absolutely. We can help put you in touch with experienced artists in the NFT space. We're here to translate your vision into 10k+ crypto masterpieces.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                    <Accordion.Header>Does your team collaborate with mine?</Accordion.Header>
                    <Accordion.Body>Indeed, we do! We consider ourselves a highly collaborative generative NFT art team. We come alongside your team, syncing up the vision and strategy to achieve lift-off for your project.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                    <Accordion.Header>Once my NFTs are ready to go, can you market my set?</Accordion.Header>
                    <Accordion.Body>We do NOT handle NFT marketing. But we DO think we're pretty good at overall advisory services, which includes our weighing in on your marketing and participating in your team meetings during the project. You definitely need to understand that marketing in the NFT space is a very tough nut to crack, and it's not always "fair." The NFT space is largely controlled by crypto influencers, and so you need to work hard on either getting some of these influencers on your team, getting in front of them in some other way, or perhaps just growing your drop more organically over time.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                    <Accordion.Header>How does your team help this all go smoothly?</Accordion.Header>
                    <Accordion.Body>From beginning to end, we're by your side to keep the project moving! There are SO many aspects to a successful NFT drop — art preparation, rarity tables, super rares, allow lists, pricing, marketing, reveals, community education, social media, and so much mroe. We've been there many times and provide guidance to your team on these issues. </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9">
                    <Accordion.Header>How do you ensure quality for your art sets?</Accordion.Header>
                    <Accordion.Body>We have developed massively detailed quality control (QC) checklists gained by doing multiple NFT drops since starting down this path. Most likely, your artwork took you months to complete and will live on immutably for years to come, hopefully becoming heirloom NFTs worth a fortune. You'd be surprised at how many high-profile NFT sets coontain errors such as dupes, sloppy trait overlapping, or metadata messes. It's vital that your generative programmer is experienced and can produce a flawless set that you'll be proud of.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10">
                    <Accordion.Header>Can you use complex logic rules for generating my art?</Accordion.Header>
                    <Accordion.Body>Sure thing. While Cryptopunks and other simplistic generative sets remain popular, artists are becoming more and more sophisticated in their ideas for generative NFT art. At GenerativeNFTs, we built our proprietary generative engine from the ground up in order to offer artists the ability to truly curate their artwork with complex situations and rules, ensuring that their collections shine. Our agency is distinguished in this area and, more than a few times, we've been hired for particularly complex generations that others could not produce.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="11">
                    <Accordion.Header>What about multiple base characters?</Accordion.Header>
                    <Accordion.Body>Yes! Why do just one base character when you can do many? Again, our system allows this and you can achieve more variety than ever before, attracting all sorts of buyers. Whatever you can think up, we can probably handle!</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="12">
                    <Accordion.Header>How about innovative metadata ideas?</Accordion.Header>
                    <Accordion.Body>We can do them! Our system allows full control over metadata production, meaning that we can accomplish considerably more than some random off-the-shelf repo. We can jockey things around, incorporating your 1/1 rare items. We can add or subtract metas to help guide rarity bots toward the NFTs you want to feature. Heck, we can even Rickroll your customers if you want (long story there, but we did this once, and the team's Discord thought it was epic!).</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="13">
                    <Accordion.Header>Can your minting pages handle tons of traffic?</Accordion.Header>
                    <Accordion.Body>Yep! Want to know what's worse than not selling? How about becoming so popular that your server crashes? That's why we use ultra-powerful, scalable servers proven to take a boatload of traffic at once. We've handled serious drops that have taken millions of hits in minutes.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="14">
                    <Accordion.Header>Do you offer multiple (and unique) reveal options?</Accordion.Header>
                    <Accordion.Body>Let's face it: not all NFT drops are the same. Some sell out in 15 minutes, some in 15 days, and some maybe never sell out. Many of these situations need different reveal scenarios, and many take the secondary market into account as well. Do you reveal instantly? Do you want 24 hours? 48 hours? Or, perhaps there are other possibilities. We have the tech and experience to roll out an optimal strategy for your drop, even if it means being flexible.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="15">
                    <Accordion.Header>Can you minimize my gas fees on my minting page?</Accordion.Header>
                    <Accordion.Body>With the current state of Ethereum, gas is always a concern. But, we've got experience in mitigating these situations so that your buyers enjoy the best experience currently available. Via presales, whitelists, and timed/stepped sale roll-outs, we've been able to considerably reduce this extra expense for many drops.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="16">
                    <Accordion.Header>Do your smart contracts allow airdropping, presales, and multiple whitelists?</Accordion.Header>
                    <Accordion.Body>Yes, yes, and yes! These items are a part of our standard toolbox for drops, and they're all highly customizable.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="17">
                    <Accordion.Header>Can you handle all the unique, complex ideas I have for my NFT?</Accordion.Header>
                    <Accordion.Body>Most likely, we can! We're unafraid to look at pioneering situations and complex solutions. The NFT space is the Wild West, after all, so let's dream up features never done before!</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="18">
                    <Accordion.Header>How much does it cost to do an NFT drop?</Accordion.Header>
                    <Accordion.Body>There are various costs involved, depending on your needs. For example, if you need an artist, you'll have to hire one. Same for other members of your team. As for our agency, our pricing structure is custom for each project. Generally speaking, we charge some ETH up front (and at launch), a percentage of the primary sale, and also some NFTs from the set.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="19">
                    <Accordion.Header>What is the timing like for an NFT drop?</Accordion.Header>
                    <Accordion.Body>If you're developing your artwork still, that could take anywhere from two weeks (ish) to a few months, depending on the speed at which your artist can produce the type of work you need. If you're not yet at that stage, then there's time for conceptualizing, planning, and assembling a team. Once your artwork is generally ready to go, our experience is that two to three weeks is about as fast as you'll probably want to move for us to work up the art coding, smart contract, minting application, and other important details. We do try to be agile and are able to jump on quicker opportunities if something hot arises. But in general most drops take about that long, or longer.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="20">
                    <Accordion.Header>Will you handle my drop?</Accordion.Header>
                    <Accordion.Body>We absolutely will need to chat first, and we do have client acceptance policies in place. We do this for a few reasons: First, we're often dealing with millions of dollars in potential revenues. So we want to ensure that we're working with reputable people, of course. We also reserve the right to decide which projects resonate with us. We've literally met with hundreds of clients worldwide over the years, and sometimes it's just not a match for whatever reason.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="21">
                    <Accordion.Header>Will you do a drop as anon devs?</Accordion.Header>
                    <Accordion.Body>Sure, maybe. We've been asked to be anonymous a few times. (Most specifically want us to NOT be anon, as they like to list us on their web site as advisors, which confers a bit of "street cred" in terms of trustworthiness.) But sure, we understand that sometimes groups like an air of mystery about themselves. As long as we're on board with the project in general (i.e. it's legit and not a rug or something), then we're cool to be background devs.</Accordion.Body>
                </Accordion.Item>

            </Accordion>

            <div className="clear"></div>

            <h2>❓ Got more questions?</h2>
            <p>Feel free to reach out: ➡️➡️➡️ Drop Jim a line at <b>Jim [at] GenerativeNFTs.io</b> ⬅️⬅️⬅️</p>            

            <div className="clear"></div>
            <p className="topButton"><a href="#top">/top</a></p>

            <div className="clear"></div>
            
        </div>

        <MainFooter />

    </div>
  );
}

export default Faq;
