// Contact.js

import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Contact = () => {
  return (
    <div>
         
         <MainHeader />

         <span id="top"></span>
        <div className="mainpage">

            <p className="menuLinks">
              <a href="/">/home</a>
              <a href="about">/about</a>
              <a href="services">/services</a>
              <a href="portfolio">/portfolio</a>
              <a href="blog">/blog</a>
              <a href="faq">/faq</a>
              <a href="contact" className="active">/contact</a>
            </p>
            
            <h1>We are accessible. Let's talk NFTs.</h1>

            <img alt="cool pic" src="contact-cats.webp" className='float-end sitepix'/>
            <p>Whether you're a digital artist new to NFTs, a member of a team of artists or investors developing an NFT idea, a celebrity or A-lister agent, a corporate brand rep, or even a defi entrepreneur, we're here to help you. We built a first-class rocketship to take you to the moon!</p>

            <p>➡️➡️➡️ <b>Drop Jim a line at Jim [at] GenerativeNFTs.io</b> ⬅️⬅️⬅️</p>
                    
            <div className="clear"></div>
            
        </div>

        <MainFooter />

    </div>
  );
}

export default Contact;
